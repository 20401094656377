import React from 'react';
import { useTranslation } from 'react-i18next';

import ImageSlider from './ImageSlider';

const RoomItem = ({room}) => {
    const { t, i18n } = useTranslation();

  return (
    // <div className='col-11 col-xxl-10 bg-color-dark my-5 p-3 py-5 d-flex flex-column flex-xl-row justify-content-center align-items-center align-items-xl-stretch column-gap-5 row-gap-3'>
    //     <div className='col-11 col-md-10 col-xl-6'>
    //         <ImageSlider images={room.images} />
    //     </div>
    //     <div className='col-11 col-md-10 col-xl-5 h-100 d-flex flex-column justify-content-between align-items-start row-gap-3'>
    //         <div>
    //             <h2 className='text-white font-mont-semibold'>{i18n.language === 'en' ? room.translations?.en?.name : room.translations?.en?.name}</h2>
    //             <p className='text-grey-color font-mont-light text-responsive-1'>*Включено сніданок і вечерю для 2-ох осіб*</p>
                
    //             <div className='col-12 my-4 d-flex flex-wrap justify-content-around align-items-center column-gap-1 row-gap-3'>
    //                 {room.room_amenities.map((amenity, key) => (
    //                     <div key={key} className='col-5 col-sm-5 col-md-4 col-lg-3 col-xl-5 col-xxl-2 d-flex flex-row align-items-center'>
    //                         <div className='col-3 col-md-2'>
    //                             <img src={`${amenity.icon}`} className='img-fluid' />
    //                         </div>
    //                         <p className='text-grey-color fs-6 font-mont-light m-0 ms-2'>{i18n.language === 'en' ? amenity?.translations?.en?.name : amenity?.translations?.uk?.name}</p>
    //                     </div>
    //                 ))}
    //             </div>

    //             <div className='mb-2 mt-4'>
    //                 <p className='text-white font-mont-light fs-2 m-0'>{room.price} Грн/Ніч</p>
    //             </div>
    //         </div>

    //         <div className='col-12 col-md-12 col-lg-8 col-xl-12 d-flex flex-column flex-sm-row justify-content-between align-items-center row-gap-3'>
    //             <a href={`/rooms/${room.id}/${room.translations.en.name}`} className='text-white fs-4 px-5 py-2 button-hover-right-fill-skin border border-color-skin'>Докладніше</a>
    //             <a href='https://www.booking.com/hotel/ua/oaza-iasinia.cs.html#room_110467301' target="_blank" className='text-white fs-4 px-5 py-2 bg-color-light-skin border border-color-skin button-hover-darker'>Забронювати</a>
    //         </div>
    //     </div>
    // </div>

    <div className='col-11 col-sm-8 col-md-5 col-xl-5 h-auto bg-color-dark p-0 position-relative d-flex flex-column align-self-stretch align-self-start column-gap-2 row-gap-2 overflow-hidden room-item-amenities-container'>
        <div className='col-12'>
            <img src={room.room_main_image} className="img-fluid" />
        </div>

        <div className='h-100 d-flex flex-column align-items-center row-gap-2 py-1 room-item-info-container'>
            <div className='col-11 pb-2 border-bottom border-1 border-secondary d-flex justify-content-between'>
                <p className='col-7 col-lg-8 text-white font-mont-light text-responsive-1'>{i18n.language === 'en' ? room.translations?.en?.name : room.translations?.uk?.name}</p>
                <p className='col-5 col-lg-5 text-white font-mont-light text-responsive-2'>{room.price} Грн/Ніч</p>
            </div>

            <div className='col-11 bg-color-dark mb-1 d-flex flex-row column-gap-2 room-item-buttons-container'>
                <a href={`/rooms/${room.id}/${room.translations.en.name}`} className='col-6 text-white text-center fs-5 button-hover-right-fill-skin border border-color-skin py-2 my-1'>Докладніше</a>
                <a href='https://www.booking.com/hotel/ua/oaza-iasinia.cs.html#room_110467301' target="_blank" className='col-6 text-white text-center fs-5 bg-color-light-skin border border-color-skin py-2 my-1 buton-hover-skin'>{t("book_room_button")}</a>
            </div>
        </div>
    </div>
  )
}

export default RoomItem