import React, {useEffect, useState} from 'react';
import axios from 'axios';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useTranslation, Trans } from 'react-i18next';

import '../static/css/roomsPage.css';

import RoomItem from '../components/RoomItem';
import Footer from '../components/Footer';

const RoomsPage = () => {
    gsap.registerPlugin(ScrollTrigger);
    const { t } = useTranslation();
    const [rooms, setRooms] = useState([]);

    useEffect(() => {
        axios.get('/api/rooms/').then((response) => {
            setRooms(response.data);
        })
    }, [])

  return (
    <div className='d-flex flex-column align-items-center'>
        <section className='bg-color-dark d-flex justify-content-center overflow-hidden hero_section-background-image' id='rooms-page-hero-section' style={{height: "100vh"}}>

            <div className='col-10 col-md-10 d-flex flex-column justify-content-center row-gap-5' style={{height: "100vh"}}>
                    <div className='col-12 col-md-8 my-2'>
                        <h1 className='text-white font-mont-bold text-title-2'>{<Trans i18nKey='rooms_page_hero_section_title' components={{ gold: <span className="text-color-dark-skin"/> }}/>}</h1>
                    </div>

                    <div className='col-12 d-flex justify-content-end mt-5'>
                        <div className='col-md-7 col-xl-5'>
                            <p className='text-responsive-1 font-mont-light text-white'>Наші номери обладнано всіма необхідними речами, щоб ваше перебування у нашому готелі відчувалось наче вдома. А прекрасні краєвиди, які відкриваються із вікон, тільки більше прикрасять вашу подорож, зробивши її ще кращою.</p>
                        </div>
                    </div>
            </div>
        </section>

        <section className='col-xxl-9 d-flex flex-wrap justify-content-evenly align-items-center align-items-md-start row-gap-5 pt-5 my-5'>
            {rooms.map((room, key) => (
                <RoomItem key={key} room={room} />
            ))}
        </section>

        <Footer />
    </div>
  )
}

export default RoomsPage