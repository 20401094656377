import React, {useEffect, useState} from 'react';
import gsap from 'gsap';
import axios from 'axios';
import Cookies from 'js-cookie';
import DatePicker from "react-datepicker";
import { format } from 'date-fns';


import "react-datepicker/dist/react-datepicker.css";
import "../static/css/bookingPage.css";

import BookingAvailableRoom from '../components/booking-page-stages/BookingAvailableRoom';
import BookingClientInformation from '../components/booking-page-stages/BookingClientInformation';
import BookingPaymentInformation from '../components/booking-page-stages/BookingPaymentInformation';

import { ReactComponent as Destination } from '../assets/destination.svg';
import { ReactComponent as DateSelector } from '../assets/dateSelector.svg';
import { ReactComponent as Keycard } from '../assets/keycard.svg';
import { ReactComponent as Search } from '../assets/search.svg';
import { ReactComponent as ProgressBar } from '../assets/bookingProgressBar.svg';

const BookingPage = () => {
  const [bookingStage, setBookingStage] = useState(1);
  const [availableRooms, setAvailableRooms] = useState([]);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [chosenRoom, setChosenRoom] = useState([])
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [amount, setAmount] = useState(0);
  const [bookingData, setBookingData] = useState([]);

  let onSubmitSearchForm = (event) => {
    event.preventDefault();
    axios.get(`/api/booking/${format(new Date(startDate), 'yyyy-MM-dd')}to${format(new Date(endDate), 'yyyy-MM-dd')}/`).then((response) => {
      setAvailableRooms(response.data);
    });
    gsap.to("#progress-bar-colored", {strokeDashoffset: 1600, duration: 1});
    gsap.to("#stage-two", {fill: "#E2A971", duration: 0.5, delay: 0.8});
  }

  let saveBookingToDatabase = async (choosenRoom, totalPrice) => {
    const csrftoken = Cookies.get('csrftoken');

    const data = {
      room: choosenRoom,
      check_in: format(new Date(startDate), "yyyy-MM-dd"),
      check_out: format(new Date(endDate), "yyyy-MM-dd"),
      adults: 2,
      children: 0,
      total_price: totalPrice
    }

    axios.defaults.headers.post['X-CSRFToken'] = csrftoken;
    axios.post('/api/booking/', data, {headers: {'Content-Type': 'application/json'}, withCredentials: false}).then((response) => {
      setBookingData(response.data);
    });
  }

  let updateBookingWithPersonalData = async (firstNameValue, lastNameValue, phoneValue, emailValue) => {
    const csrftoken = Cookies.get('csrftoken');

    const data = {
      id: bookingData?.id,
      room: bookingData?.room,
      check_in: bookingData?.check_in,
      check_out: bookingData?.check_out,
      adults: bookingData?.adults,
      children: bookingData?.children,
      total_price: bookingData?.total_price,
      first_name: firstNameValue,
      last_name: lastNameValue,
      phone: phoneValue,
      email: emailValue
    }

    axios.put(`/api/booking/${bookingData?.id}/`, data, {headers: {'X-CSRFToken': csrftoken, 'Content-Type': 'application/json'}, withCredentials: false}).then((response) => {
      setBookingData(response.data);
    });

    setBookingStage(3);
  }

  return (
    <div className='bg-color-dark'>
        <section className='position-relative d-flex justify-content-center' style={{minHeight: "100vh"}}>
          <div className='col-xl-11 col-xxl-9 bg-white header-offset'>
            <div className='py-4'><h1 className='text-center text-title-3 font-cormorant-regular'>Бронювання</h1></div>

            <form className='col-12 bg-color-dark py-5 d-flex flex-wrap justify-content-around row-gap-4' onSubmit={onSubmitSearchForm}>
              <div className='col-12 col-lg-9 d-flex flex-wrap justify-content-around justify-content-md-between row-gap-3'>
                <div className='bg-white w-auto px-3 py-3 d-flex flex-row align-items-center'>
                  <Destination />
                  <h5 className='fw-regular my-0 ms-2 fw-medium'>“ОАЗА” Драгобрат</h5>
                </div>

                <div className='col-11 col-sm-6 col-md-5 bg-white position-relative px-3 py-1 d-flex flex-row justify-content-center align-items-center'>
                  <DateSelector />
                  <div className='d-flex flex-row'>
                    <DatePicker
                      className='text-center fs-4 border-0 border-end border-1'
                      selectsStart
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      startDate={startDate}
                      dateFormat="yyyy.MM.dd"
                    />
                    <DatePicker
                      className='text-center fs-4 border-0'
                      selectsEnd
                      selected={endDate}
                      onChange={(date) => setEndDate(date)}
                      endDate={endDate}
                      startDate={startDate}
                      minDate={startDate}
                      dateFormat="YYYY.MM.dd"
                    />
                  </div>
                </div>

                <div className='col-11 col-sm-5 col-md-3 bg-white d-flex justify-content-around align-items-center'>
                  <div className='col-4 h-100 px-2 py-2 border-end border-black border-3 d-flex justify-content-center align-items-center'>
                    <Keycard />
                    <h3 className='my-0 ms-2'>1</h3>
                  </div>

                  <div className='col-4 h-100 px-2 py-2 border-end border-black border-3 d-flex justify-content-center align-items-center'>
                    <Keycard />
                    <h3 className='my-0 ms-2'>2</h3>
                  </div>

                  <div className='col-4 h-100 px-2 py-2 d-flex justify-content-center align-items-center'>
                    <Keycard />
                    <h3 className='my-0 ms-2'>0</h3>
                  </div>
                </div>
              </div>
              
              <div className='col-auto bg-color-golden-skin d-flex position-relative cursor-pointer booking-form-search-button'>
                <button className='w-100 text-black fs-4 px-2 py-3 bg-transparent border-0 d-flex justify-content-between align-items-center' type='submit'>Забронювати <Search className="mt-1 ms-2" /></button>
              </div>
            </form>

            <div className='col-12 d-flex justify-content-center my-5'>
              <div className='col-10 d-flex justify-content-center'>
                <ProgressBar />
              </div>
            </div>

            {bookingStage == 1 &&
              <div className='d-flex flex-column align-items-center'>
                {availableRooms.map((availableRoom, key) => (
                  <BookingAvailableRoom key={key} room={availableRoom} choosenRoom={setChosenRoom} startDate={startDate} endDate={endDate} amount={setAmount} saveToDB={saveBookingToDatabase} changeStage={setBookingStage} />
                ))}
              </div>
            }

            {bookingStage == 2 &&
              <div>
                <BookingClientInformation firstName={setFirstName} lastName={setLastName} phone={setPhone} email={setEmail} saveToDB={updateBookingWithPersonalData} changeStage={setBookingStage} />
              </div>
            }

            {bookingStage == 3 &&
              <div>
                <BookingPaymentInformation chosenRoom={chosenRoom} firstName={firstName} lastName={lastName} checkIn={startDate} checkOut={endDate} phone={phone} email={email} amount={amount} bookingData={bookingData} />
              </div>
            }
          </div>
        </section>
    </div>
  )
}

export default BookingPage