import React from 'react';
import gsap from 'gsap';

const BookingClientInformation = ({firstName, lastName, phone, email, saveToDB, changeStage}) => {

    let submitClientInformationForm = async (event) => {
        event.preventDefault();
        let firstNameValue = document.getElementById("first-name-field").value;
        let lastNameValue = document.getElementById("last-name-field").value;
        let phoneValue = document.getElementById("phone-field").value;
        let emailValue = document.getElementById("email-field").value;
        await firstName(firstNameValue);
        await lastName(lastNameValue);
        await phone(phoneValue);
        await email(emailValue);
        await saveToDB(firstNameValue, lastNameValue, phoneValue, emailValue);
        gsap.to("#progress-bar-colored", {strokeDashoffset: 960, duration: 1, delay: 0.1});
        gsap.to("#stage-four", {fill: "#E2A971", duration: 0.5, delay: 0.9});
    }

  return (
    <div className='col-12 d-flex justify-content-center'>
        <form className='col-8 mt-5' onSubmit={submitClientInformationForm}>
            <div>
                <h1 className=''>Guest Information</h1>
            </div>

            <div className='col-12 my-5 d-flex flex-wrap justify-content-between row-gap-5'>
                <div className='col-5 position-relative'>
                    <p className='fs-4 bg-white px-2 ms-3 position-absolute top-0 translate-middle-y'>First Name</p>
                    <input type='text' required className='form-control border-black border-1 fs-5 pt-4' id='first-name-field' />
                </div>

                <div className='col-5 position-relative'>
                    <p className='fs-4 bg-white px-2 ms-3 position-absolute top-0 translate-middle-y'>Last Name</p>
                    <input type='text' required className='form-control border-black border-1 fs-5 pt-4' id='last-name-field' />
                </div>

                <div className='col-5 position-relative'>
                    <p className='fs-4 bg-white px-2 ms-3 position-absolute top-0 translate-middle-y'>Phone Number</p>
                    <input type='text' required className='form-control border-black border-1 fs-5 pt-4' id='phone-field' />
                </div>

                <div className='col-5 position-relative'>
                    <p className='fs-4 bg-white px-2 ms-3 position-absolute top-0 translate-middle-y'>E-mail Address</p>
                    <input type='email' required className='form-control border-black border-1 fs-5 pt-4' id='email-field' />
                </div>
            </div>

            <div className='col-12 my-5 pt-5 d-flex justify-content-end'>
                <button type='submit' className='text-dark fs-5 bg-color-golden-skin px-4 py-2 border-0 rounded-3 cursor-pointer guest-information-submit-button'>Продовжити</button>
            </div>
        </form>
    </div>
  )
}

export default BookingClientInformation