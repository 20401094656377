import React from 'react';
import ReactDOM from 'react-dom/client';
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';

import App from './App';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    supportedLngs: ['ua', 'en'],
    fallbackLng: "ua",
    detection: {
      order: ['path', 'cookie', 'localStorage', 'sessionStorage', 'htmlTag',  'navigator', 'querystring', 'subdomain'],
      caches: ['cookie'],
    },
    backend: {
      loadPath: '/static/assets/locales/{{lng}}/translation.json',
      // loadPath: '/assets/locales/{{lng}}/translation.json',
    },
    react: { useSuspense: false },
    interpolation: {
      escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    }
  });

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
