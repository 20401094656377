import React from 'react';
import { useTranslation } from 'react-i18next';

import ImageSlider from './ImageSlider';

const FavoriteRooms = ({favoriteRoom, index}) => {

    const { t, i18n } = useTranslation();

  return (
    <div className='d-flex flex-column align-items-center'>
        {index % 2 == 0 ?
            <div className='row m-0 px-0 py-5 py-from-lg-3 bg-color-dark d-flex justify-content-center'>
                <div className='col-sm-10 col-md-9 col-lg-11 col-xl-10 col-xxl-8 py-5 my-5 me-3 my-from-lg-0 me-from-lg-0 row-gap-4 column-gap-5 d-flex flex-column flex-lg-row justify-content-between align-items-center align-items-lg-stretch'>
                    <div className='col-12 col-sm-11 col-lg-6'>
                        <ImageSlider images={favoriteRoom.images} />
                    </div>

                    <div className='col-11 col-lg-6 d-flex flex-column justify-content-between align-items-start'>
                        <div>
                            <h1 className='text-title-1 text-white font-mont-semibold'>{i18n.language === 'en' ? favoriteRoom.translations?.en?.name : favoriteRoom.translations.uk.name}</h1>
                            <p className='text-white fs-4 font-mont-light'>{i18n.language === 'en' ? favoriteRoom.translations?.en?.description : favoriteRoom.translations.uk.description}</p>
                        </div>

                        <div className='col-12 my-4 d-flex flex-wrap justify-content-between align-items-center column-gap-3 row-gap-3'>
                            {favoriteRoom.room_amenities.map((amenity, key) => (
                                <div key={key} className='col-4 col-sm-3 col-md-4 col-lg-3 col-xl-5 col-xxl-2 d-flex flex-row align-items-center'>
                                    <div className='col-3 col-md-2 col-xl-2 col-xxl-4'>
                                        <img src={`${amenity.icon_dark ? amenity.icon_dark : amenity.icon_light}`} className='img-fluid' />
                                    </div>
                                    <p className='text-secondary fs-5 font-mont-light m-0 ms-2'>{i18n.language === 'en' ? amenity.translations.en.name : amenity.translations.uk.name}</p>
                                </div>
                            ))}
                        </div>

                        <div>
                            <p className='text-white fs-4 font-mont-light'>{favoriteRoom.price} {t('room_price_daily')}</p>
                        </div>

                        <div className='col-12 col-md-8 col-xl-11 col-xxl-10 d-flex flex-row column-gap-2'>
                            <a href='/rooms/' className='col-6 text-white text-center fs-5 button-hover-right-fill-skin border border-color-skin py-2 my-1'>{t("check_all_rooms_button")}</a>
                            <a href='https://www.booking.com/hotel/ua/oaza-iasinia.cs.html#room_110467301' target="_blank" className='col-6 text-white text-center fs-5 bg-color-light-skin border border-color-skin py-2 my-1 buton-hover-skin'>{t("book_room_button")}</a>
                        </div>
                    </div>
                </div>
            </div>
        :
            <div className='row m-0 px-0 py-5 my-5 my-from-lg-0 d-flex justify-content-center'>
                <div className='col-sm-10 col-md-9 col-lg-11 col-xl-10 col-xxl-8 py-5 ms-3 ms-from-lg-0 row-gap-4 column-gap-5 d-flex flex-column flex-lg-row-reverse justify-content-between align-items-center align-items-lg-stretch'>
                    <div className='col-12 col-sm-11 col-lg-6'>
                        <ImageSlider images={favoriteRoom.images} />
                    </div>

                    <div className='col-11 col-lg-5 col-xl-6 d-flex flex-column justify-content-between align-items-start'>
                        <div>
                            <h1 className='text-title-1 text-dark font-mont-semibold'>{i18n.language === 'en' ? favoriteRoom.translations?.en?.name : favoriteRoom.translations.uk.name}</h1>
                            <p className='text-dark fs-4 font-mont-light'>{i18n.language === 'en' ? favoriteRoom.translations?.en?.description : favoriteRoom.translations.uk.description}</p>
                        </div>

                        <div className='col-12 my-4 d-flex flex-wrap justify-content-between align-items-center column-gap-3 row-gap-3'>
                            {favoriteRoom.room_amenities.map((amenity, key) => (
                                <div key={key} className='col-4 col-sm-3 col-md-4 col-lg-3 col-xl-5 col-xxl-2 d-flex flex-row align-items-center'>
                                    <div className='col-3 col-md-2 col-xl-2 col-xxl-4'>
                                        <img src={`${amenity.icon_light ? amenity.icon_light : amenity.icon_dark}`} className='img-fluid' />
                                    </div>
                                    <p className='text-secondary fs-5 font-mont-light m-0 ms-2'>{i18n.language === 'en' ? amenity.translations.en.name : amenity.translations.uk.name}</p>
                                </div>
                            ))}
                        </div>

                        <div>
                            <p className='fs-4 font-mont-light'>{favoriteRoom.price} {t('room_price_daily')}</p>
                        </div>

                        <div className='col-12 col-md-9 col-lg-12 col-xl-11 col-xxl-10 d-flex flex-row column-gap-2'>
                            <a href='/rooms/' className='col-6 text-dark text-center fs-4 button-hover-right-fill-dark border border-color-darker py-2 my-1'>{t("check_all_rooms_button")}</a>
                            <a href='https://www.booking.com/hotel/ua/oaza-iasinia.cs.html#room_110467301' target="_blank" className='col-6 text-white text-center fs-4 bg-color-darker border border-dark py-2 my-1 button-hover-darker'>{t("book_room_button")}</a>
                        </div>
                    </div>
                </div>
            </div>
        }   
    </div>
  )
}

export default FavoriteRooms