import React, { useEffect } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { MotionPathPlugin } from 'gsap/MotionPathPlugin';

import Footer from '../components/Footer';

import '../static/css/aboutHotelPage.css';

import { ReactComponent as Line } from '../assets/aboutHotelLineBigScreen.svg';
import { ReactComponent as LineMobile } from '../assets/aboutHotelLineMobileUpdated.svg';
import { ReactComponent as ArrowDown } from '../assets/arrowDown.svg';

const AboutHotelPage = () => {
    gsap.registerPlugin(ScrollTrigger, MotionPathPlugin);

    useEffect(() => {
        let mobileSVGAnimation = gsap.matchMedia();

        gsap.fromTo(".sectioned-image-from-bottom", {y: 50}, {
            y: 0,
            scrollTrigger: {
            trigger: "#sectioned-images-section",
            start: "20% bottom",
            end: "30% center",
            scrub: 1
        }});
        gsap.fromTo(".sectioned-image-from-top", {y: -50}, {
            y: 0,
            scrollTrigger: {
                trigger: "#sectioned-images-section",
                start: "20% bottom",
                end: "30% center",
                scrub: 1
            }
        });

        mobileSVGAnimation.add("(min-width: 861px)", () => {
            gsap.set("#from_sauna_to_restaurant_line", {height: 0, strokeDasharray: 1920, strokeDashoffset: 1920});
            gsap.to("#from_sauna_to_restaurant_line", {
                strokeDasharray: 1920,
                strokeDashoffset: 0,
                scrollTrigger: {
                    trigger: '.svg-section',
                    start: 'top 70%',
                    end: '25% 70%',
                    scrub: true
                }
            });

            gsap.set("#from_restaurant_to_parking_line", {height: 0, strokeDasharray: 1920, strokeDashoffset: -1920});
            gsap.to("#from_restaurant_to_parking_line", {
                strokeDasharray: 1920,
                strokeDashoffset: 0,
                scrollTrigger: {
                    trigger: '.svg-section',
                    start: '30% 70%',
                    end: '45% 70%',
                    scrub: true
                }
            });

            gsap.set("#from_parking_to_bottom", {height: 0, strokeDasharray: 1920, strokeDashoffset: 2000});
            gsap.to("#from_parking_to_bottom", {
                strokeDasharray: 1920,
                strokeDashoffset: 0,
                scrollTrigger: {
                    trigger: '.svg-section',
                    start: '50% 70%',
                    end: '85% bottom',
                    scrub: true
                }
            });
        });

        mobileSVGAnimation.add("(max-width: 860px)", () => {
            gsap.set("#from_sauna_to_restaurant_line", {height: 0, strokeDasharray: 1920, strokeDashoffset: 1920});
            gsap.to("#from_sauna_to_restaurant_line", {
                strokeDasharray: 1920,
                strokeDashoffset: 1000,
                scrollTrigger: {
                    trigger: '.svg-section',
                    start: 'top 70%',
                    end: '30% top',
                    scrub: 1
                }
            });

            gsap.set("#from_restaurant_to_parking_line", {height: 0, strokeDasharray: 1920, strokeDashoffset: 1920});
            gsap.to("#from_restaurant_to_parking_line", {
                strokeDasharray: 1960,
                strokeDashoffset: 1300,
                scrollTrigger: {
                    trigger: '.svg-section',
                    start: '31% 70%',
                    end: '60% 70%',
                    scrub: 1
                }
            });

            gsap.set("#from_parking_to_bottom", {height: 0, strokeDasharray: 1920, strokeDashoffset: 1920});
            gsap.to("#from_parking_to_bottom", {
                strokeDasharray: 1920,
                strokeDashoffset: 1000,
                scrollTrigger: {
                    trigger: '.svg-section',
                    start: '65% 70%',
                    end: '100% bottom',
                    scrub: 1
                }
            });
        });
    })

  return (
    <div className='bg-color-dark'>
        <section className='container-fluid d-flex flex-column justify-content-around align-items-center mb-5' style={{height: "100vh"}}>
            <div className='col-xl-11 h-100 h-xxl-65 d-flex flex-column flex-xl-row justify-content-start justify-content-xl-between align-items-center align-items-xl-start header-offset'>
                <div className='col-md-11 col-lg-10 col-xl-5 mb-sm-5'>
                    <h1 className='text-color-dark-skin font-mont-semibold text-title-2 fw-bold'>Про готель</h1>
                    <p className='text-white text-responsive-1 font-mont-light'>Ідеальне місце для тих, хто хоче забути про буденну суєту, розмістившись в затишних номерах, обладнаних всім необхідним для приємного відпочинку в оточенні чарівної природи Карпатських гір. Наші переваги : унікальне розташування (1400м над р.м.),чарівна природа, близькість до власних підйомників, лижна та сноуборд школа, прокат спорядження, розваги для дітей, панорамний вигляд, смачна кухня, європейський сервіс. Все в комплексі зробить ваше перебування тут приємним та незабутнім.</p>
                </div>

                <div className='col-11 col-sm-10 col-md-9 col-xl-6 d-flex flex-row justify-content-end column-gap-4 about-page-hero-image-section'>
                    <div className='col-4 h-100 about-page-vertical-hero-image'></div>
                    <div className='col-12 col-sm-7 h-100 d-flex flex-column row-gap-4'>
                        <div className='about-page-horizontal-upper-hero-image'></div>
                        <div className='about-page-horizontal-lower-hero-image'></div>
                    </div>
                </div>
            </div>
        </section>

        <section className='container-fluid p-0 m-0 d-flex justify-content-center align-items-center position-relative overflow-hidden svg-section'>
            <div className='col-11 col-xl-10 d-flex justify-content-center align-items-center'>
                {window.innerWidth > 860 ? <Line /> : <LineMobile /> }
                <div className='col-12 col-md-7 d-flex flex-column justify-content-around justify-content-md-between align-items-center align-items-md-start position-absolute top-0 about-page-animated-svg-section-text-container'>
                    <div className='col-8 col-sm-7 col-xl-6 about-page-animated-svg-section-text'>
                        <h1 className='text-color-dark-skin font-mont-semibold'>Сауна</h1>
                        <p className='text-white font-mont-light about-page-animated-svg-section-description-text mb-0'>В готелі можна розслабитись є невелика сауна (на 4 особи) з контрастним басейном та куточком, для відпочинку.</p>
                        <p className='text-color-dark-skin font-mont-semibold about-page-animated-svg-section-description-text'>Вартість 500грн/год.</p>
                    </div>

                    <div className='col-8 col-sm-7 col-md-9 col-xl-7 align-self-center align-self-md-end about-page-animated-svg-section-text'>
                        <h1 className='text-color-dark-skin font-mont-semibold'>Ресторан</h1>
                        <p className='text-white font-mont-light about-page-animated-svg-section-description-text'>Відвідавши наш комплекс, ви маєте можливість насолодитися стравами гуцульської, української та європейської кухні в найвисокогірнішому ресторані України. Скуштувати ароматний трав’яний чай на відкритій терасі, з якої відкривається неймовірний пейзаж на вершини навколишніх гір.</p>
                    </div>

                    <div className='col-8 col-sm-7 col-xl-6 about-page-animated-svg-section-text'>
                        <h1 className='text-color-dark-skin font-mont-semibold'>Паркінг</h1>
                        <p className='text-white font-mont-light about-page-animated-svg-section-description-text'>Місце, щоб зручно розсташувати свій автомобіль одразу біля готелю.</p>
                    </div>
                </div>
            </div>
        </section>

        <section className='d-flex justify-content-center overflow-x-hidden' id='sectioned-images-section' style={{minHeight: "60vh"}}>
            <div className='col-11 h-100 d-flex flex-row justify-content-center column-gap-5'>
                <div className='col-6 col-md-5 col-lg-3 col-xxl-2 about-hotel-page-sectioned-images sectioned-image-from-top'></div>
                <div className='col-6 col-md-5 col-lg-3 col-xxl-2 about-hotel-page-sectioned-images sectioned-image-from-bottom'></div>
                <div className='col-6 col-md-5 col-lg-3 col-xxl-2 about-hotel-page-sectioned-images sectioned-image-from-top'></div>
                <div className='col-6 col-md-5 col-lg-3 col-xxl-2 about-hotel-page-sectioned-images sectioned-image-from-bottom'></div>
                <div className='col-6 col-md-5 col-lg-3 col-xxl-2 about-hotel-page-sectioned-images sectioned-image-from-top'></div>
            </div>
        </section>

        <section className='container-fluid m-0 pb-5 d-flex flex-column align-items-center'>
            <div className='d-flex flex-column align-items-center'>
                <h1 className='text-white text-center text-md-start text-title-2 font-libre-caslon mb-5'>Створюйте незабутні моменти разом з нами</h1>
                <ArrowDown />
            </div>
            
            <a href='/rooms/' className='bg-transparent text-white button-hover-right-fill-skin border border-color-skin px-5 py-2 fs-2 my-5 buton-hover-skin'>Номери</a>
        </section>

        <Footer />
    </div>
  )
}

export default AboutHotelPage