import React from 'react';
import { useTranslation } from 'react-i18next';

import '../static/css/footer.css';

import OazaLogoImage from '../assets/img/oaza-logo.png';

import { ReactComponent as Facebook } from '../assets/socials/facebook.svg';
import { ReactComponent as Instagram } from '../assets/socials/instagram.svg';
import { ReactComponent as OazaLogo } from '../assets/oazaLogoPhone.svg';

const Footer = () => {

    const { t, i18n } = useTranslation();

  return (
    <div className='container-fluid h-auto pt-5 bg-color-dark overflow-hidden footer-container' style={{maxWidth: "100vw"}}>
        <div className='w-100 mt-5 d-flex flex-column footer-pc'>
            <div className='col-12 d-flex flex-wrap justify-content-around row-gap-5'>
                <div className='col-12 col-xxl-3 d-flex justify-content-center align-items-center'>
                    <div className='col-7 d-flex justify-content-center'>
                        <img src={OazaLogoImage} className='img-fluid' />
                    </div>
                </div>

                <div className='col-5 col-xl-4 col-xxl-3'>
                    <div className='my-4'>
                        <h3 className='text-color-darker-skin font-mont-regular'>{t('footer_address_title')}</h3>
                        <p className='text-white font-mont-regular'>{t('footer_address_info')}</p>
                    </div>

                    <div className='my-5'>
                        <h3 className='text-color-darker-skin font-mont-regular'>{t('footer_how_to_get_here_title')}</h3>
                        <p className='text-white font-mont-regular'>{t('footer_how_to_get_here_info')}</p>
                        <a href='https://www.google.com/maps/dir//%D0%9E%D0%90%D0%97%D0%90,+%D0%94%D1%80%D0%B0%D0%B3%D0%BE%D0%B1%D1%80%D0%B0%D1%82,+%D0%AF%D1%81%D1%96%D0%BD%D1%8F,+%D0%97%D0%B0%D0%BA%D0%B0%D1%80%D0%BF%D0%B0%D1%82%D1%81%D1%8C%D0%BA%D0%B0+%D0%BE%D0%B1%D0%BB%D0%B0%D1%81%D1%82%D1%8C,+%D0%A3%D0%BA%D1%80%D0%B0%D1%97%D0%BD%D0%B0,+90630/@48.2424969,24.241534,18z/data=!4m8!4m7!1m0!1m5!1m1!1s0x4737100489bbc6b3:0x78cb36c9cdb8fdec!2m2!1d24.2430343!2d48.2423717?entry=ttu' target="_blank" className='text-white font-alegreya-sans-regular fs-5 border-1 border-bottom border-white'>{t('footer_create_route')}</a>
                    </div>
                </div>

                <div className='col-5 col-xl-4 col-xxl-3 d-flex flex-column align-items-end align-items-xxl-start'>
                    <div>
                        <a href='https://www.facebook.com/oazadragobrat' target='_blank' className='mx-2'><Facebook /></a>
                        <a href='https://www.instagram.com/oaza_dragobrat?igsh=MWR2eGU4bm1sMTk2aQ==' target='_blank' className='mx-2'><Instagram /></a>
                    </div>

                    <div className='mt-4'>
                        <div className='d-flex flex-column align-items-end align-items-xxl-start'>
                            <h3 className='text-color-darker-skin font-mont-regular m-0'>{t('footer_administration_title')}</h3>
                            <p className='text-white fs-4 font-mont-regular'>+38 067 934 25 15</p>
                        </div>

                        <div className='d-flex flex-column align-items-end align-items-xxl-start'>
                            <h3 className='text-color-darker-skin font-mont-regular m-0'>{t('footer_booking_department_title')}</h3>
                            <p className='text-white fs-4 font-mont-regular'>+38 068 699 35 35 (Viber, Telegram)</p>
                        </div>

                        <div className='d-flex flex-column align-items-end align-items-xxl-start'>
                            <h3 className='text-color-darker-skin font-mont-regular m-0'>{t('footer_reception_title')}</h3>
                            <p className='text-white fs-4 font-mont-regular m-0'>+38 068 493 05 75</p>
                            <p className='fs-6 text-color-darker-skin font-mont-regular'>{t('footer_reception_do_not_make_reservations_warning')}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className='col-12 mt-4'>
                <p className='text-white text-center font-alegreya-sans-regular fs-5'>© 2024 {t('footer_rights_reserved')}  •  {t('footer_created_by')}</p>
            </div>
        </div>

        {/* Footer for small screenes */}
        <div className='footer-mobile d-flex flex-column align-items-center row-gap-5 px-2'>
            <div className='col-12 d-flex flex-row justify-content-between'>
                <div className='col-6'>
                    {/* <img src={OazaLogoImage} className='img-fluid' /> */}
                    <OazaLogo />
                </div>

                <div className='col-auto d-flex justify-content-center align-items-center'>
                    <a href='https://www.facebook.com/oazadragobrat' target='_blank' className='mx-2'><Facebook /></a>
                    <a href='https://www.instagram.com/oaza_dragobrat?igsh=MWR2eGU4bm1sMTk2aQ==' target='_blank' className='mx-2'><Instagram /></a>
                </div>
            </div>

            <div className='col-12 col-md-7'>
                <div className='d-flex flex-column align-items-start align-items-md-center my-3'>
                    <h5 className='text-color-darker-skin font-mont-regular m-0'>{t('footer_administration_title')}</h5>
                    <p className='text-white fs-4 font-mont-regular'>+38 067 934 25 15</p>
                </div>

                <div className='d-flex flex-column align-items-start align-items-md-center my-3'>
                    <h5 className='text-color-darker-skin font-mont-regular m-0'>{t('footer_booking_department_title')}</h5>
                    <p className='text-white fs-4 font-mont-regular'>+38 068 699 35 35 (Viber, Telegram)</p>
                </div>

                <div className='d-flex flex-column align-items-start align-items-md-center mt-3'>
                    <h5 className='text-color-darker-skin font-mont-regular m-0'>{t('footer_reception_title')}</h5>
                    <p className='text-white fs-4 font-mont-regular m-0'>+38 068 493 05 75</p>
                    <p className='fs-6 text-color-darker-skin font-mont-regular'>{t('footer_reception_do_not_make_reservations_warning')}</p>
                </div>
            </div>

            <div className='col-12 col-md-7'>
                <div className='mb-4'>
                    <h4 className='text-color-darker-skin font-mont-regular'>{t('footer_address_title')}</h4>
                    <p className='text-white font-mont-regular text-responsive-2'>{t('footer_address_info')}</p>
                    <a href='https://www.google.com/maps/dir//%D0%9E%D0%90%D0%97%D0%90,+%D0%94%D1%80%D0%B0%D0%B3%D0%BE%D0%B1%D1%80%D0%B0%D1%82,+%D0%AF%D1%81%D1%96%D0%BD%D1%8F,+%D0%97%D0%B0%D0%BA%D0%B0%D1%80%D0%BF%D0%B0%D1%82%D1%81%D1%8C%D0%BA%D0%B0+%D0%BE%D0%B1%D0%BB%D0%B0%D1%81%D1%82%D1%8C,+%D0%A3%D0%BA%D1%80%D0%B0%D1%97%D0%BD%D0%B0,+90630/@48.2424969,24.241534,18z/data=!4m8!4m7!1m0!1m5!1m1!1s0x4737100489bbc6b3:0x78cb36c9cdb8fdec!2m2!1d24.2430343!2d48.2423717?entry=ttu' target="_blank" className='text-white font-alegreya-sans-regular fs-5 border-1 border-bottom border-white'>{t('footer_create_route')}</a>
                </div>

                <div className='my-5'>
                    <h4 className='text-color-darker-skin font-mont-regular'>{t('footer_how_to_get_here_title')}</h4>
                    <p className='text-white font-mont-regular text-responsive-2'>{t('footer_how_to_get_here_info')}</p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Footer