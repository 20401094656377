import { useEffect, useState } from 'react'
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import { useTranslation } from "react-i18next";
import Cookies from 'js-cookie';

import './App.css';

import HomePage from './pages/HomePage';
import RoomsPage from './pages/RoomsPage';
import SkiRentalPage from './pages/SkiRentalPage';
import SingleRoomPage from './pages/SingleRoomPage';
import AboutHotelPage from './pages/AboutHotelPage';
import RestaurantPage from './pages/RestaurantPage';
import BookingPage from './pages/BookingPage';
import BookingComplition from './pages/BookingComplition';
import MenuPage from './pages/MenuPage';
import MenuContentPage from './pages/MenuContentPage';

import Header from './components/Header';
import LoadingScreen from './components/LoadingScreen';

function App() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!Cookies.get('selectedItems')) {
      Cookies.set('selectedItems', JSON.stringify([]));
    }

    setTimeout(() => setLoading(false), 3300);
  });

  return (
    <Router>
      <div>
        {loading ? <LoadingScreen/> : null}
        <Header />
        <Routes>
          <Route exact path='/' Component={HomePage} />
          <Route exact path='/rooms' Component={RoomsPage} />
          <Route path='/rooms/:id/:room_name' Component={SingleRoomPage} />
          <Route exact path='/about-hotel' Component={AboutHotelPage} />
          <Route exact path='/restaurant' Component={RestaurantPage} />
          <Route exact path='/ski-rental' Component={SkiRentalPage} />
          <Route exact path='/booking' Component={BookingPage} />
          <Route exact path='/booking/order-completion' Component={BookingComplition} />
          <Route exact path='/menu' Component={MenuPage} />
          <Route exact path='/menu/content' Component={MenuContentPage} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
