import React, {useEffect, useState} from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import '../static/css/carousel.css';

import SlideOne from '../assets/img/carousel/carousel-1.jpg';
import SlideTwo from '../assets/img/carousel/carousel-2.jpg';
import SlideThree from '../assets/img/carousel/carousel-3.png';

import { ReactComponent as ArrowRight } from '../assets/arrowToRight.svg';
import { ReactComponent as ArrowLeft } from '../assets/arrowToLeft.svg';

const CarouselTextContainer = ({slideNumber}) => {
  return (
    <div className='col-11 col-xl-9 bg-white d-flex flex-column justify-content-between align-items-center py-4 z-3 rounded-1 slide-text-container slide-text-container-side-padding' data-slide-number={slideNumber}>
      <p className='text-dark font-libre-caslon fs-5'>Random text {slideNumber}</p>
      <h1 className='text-dark font-libre-caslon'>Random text</h1>
      <p className='text-dark text-center font-libre-caslon fs-5'>Transformative experiences. Personalized service. Unparalleled destinations</p>
      <a className='text-dark font-libre-caslon fs-5 py-2 px-4 border border-dark'>Discover more</a>
    </div>
  )
}

const Carousel = () => {
  let slidingTimeline = new gsap.timeline();
  let responsivenes = gsap.matchMedia(); 
  let [animationFinished, setAnimationFinished] = useState(false);
  let [animationXValue, setAnimationXValue] = useState(0);
  let [activeSlide, setActiveSlide] = useState(2);
  let [slideWidth, setSlideWidth] = useState();
  let [sliderLastSlideAvailable, setSliderLastSlideAvailable] = useState();
  let [sliderFirstSlideAvailable, setSliderFirstSlideAvailable] = useState();
  let touchstartX = 0;
  let touchendX = 0;
  const slidesList = [1, 3, 1, 2, 3, 1];

  //Different screen sizes infinite loop slider calculations
  let smallestSizeStart = -(slideWidth + 10);
  let smallestSizeEnd = -(sliderLastSlideAvailable + (slideWidth*1.5) + 20);

  let middleSizeEnd = -(sliderLastSlideAvailable + (slideWidth*1.5) + 10);

  let biggestSizeEnd = -(sliderLastSlideAvailable + slideWidth + 15);

  useEffect(() => {
  gsap.registerPlugin(ScrollTrigger);
    const currentSlide = document.querySelectorAll(`[data-slide-number="1"]`);
    const currentSlideImgContainer = document.querySelectorAll(`[data-slide-img-container-number="1"]`);
    const slideWidth = Math.round(document.getElementById("slide-object").offsetWidth / document.documentElement.clientWidth * 100);

    setSlideWidth(slideWidth);
    setSliderLastSlideAvailable((slideWidth/2 + 5) + (slideWidth*3 + 45));
    setSliderFirstSlideAvailable((slideWidth/2 + 5) + slideWidth + 15);

    responsivenes.add("(min-width: 500px)", () => {
      currentSlideImgContainer.forEach((item) => {
        gsap.to(item, {
          width: '90%',
          duration: 0.5,
          delay: 0.3
        });
      });
    });

    gsap.set(currentSlide[1], {x: 300});
    gsap.to(currentSlide[1], {
      x: 0,
      opacity: 1,
      duration: 0.8,
      delay: 0.2,
      scrollTrigger: {
        trigger: currentSlide[0],
        start: 'top bottom',
        end: 'top bottom',
        scrub: false
      }
    });
  }, []);

  useEffect(() => {
    responsivenes.add("(max-width: 700px)", () => {
      if (animationXValue == smallestSizeStart) {
        gsap.set('.slider-container', {x: `-${sliderLastSlideAvailable + (slideWidth/2 + 5)}vw`});
      } else if (animationXValue == smallestSizeEnd) {
        gsap.set('.slider-container', {x: `-${sliderFirstSlideAvailable + slideWidth/2 + 5}vw`});
      }
    });

    responsivenes.add("(max-width: 1150px)", () => {
      if (animationXValue == -(slideWidth)) {
        gsap.set('.slider-container', {x: `-${sliderLastSlideAvailable + (slideWidth/2 - 5)}vw`});
      } else if (animationXValue == middleSizeEnd) {
        gsap.set('.slider-container', {x: `-${sliderFirstSlideAvailable + (slideWidth/2 - 5)}vw`});
      }
    });

    responsivenes.add("(min-width: 1151px)", () => {
      if (Math.round(animationXValue) == -(slideWidth/2 + 5)) {
        gsap.set('.slider-container', {x: `-${sliderLastSlideAvailable}vw`});
      } else if (Math.round(animationXValue) == biggestSizeEnd) {
        gsap.set('.slider-container', {x: `-${sliderFirstSlideAvailable}vw`});
      }
    })

    setAnimationFinished(false);

  }, [animationFinished]);

  let nextSlide = () => {
    if (slidingTimeline.isActive() == true) { return; }

    const activeClasses = document.querySelectorAll(`[data-slide-number="${slidesList[activeSlide+1]}"]`);
    const previousClasses = document.querySelectorAll(`[data-slide-number="${slidesList[activeSlide]}"]`);

    responsivenes.add("(min-width: 500px)", () => {
      const activeClassSlideImgContainer = document.querySelectorAll(`[data-slide-img-container-number="${slidesList[activeSlide+1]}"]`);
      const previousClassSlideImgContainer = document.querySelectorAll(`[data-slide-img-container-number="${slidesList[activeSlide]}"]`);

      activeClassSlideImgContainer.forEach((item) => {
        gsap.to(item, {
          width: "90%",
          duration: 0.5
        });
      });

      previousClassSlideImgContainer.forEach((item) => {
        gsap.to(item, {
          width: "66%",
          duration: 0.5
        });
      });
    });

    responsivenes.add("(max-width: 499px)", () => {
      const activeClassSlideImgContainer = document.querySelectorAll(`[data-slide-img-container-number="${slidesList[activeSlide-1]}"]`);
      const previousClassSlideImgContainer = document.querySelectorAll(`[data-slide-img-container-number="${slidesList[activeSlide]}"]`);

      activeClassSlideImgContainer.forEach((item) => {
        gsap.to(item, {
          width: "90%",
          duration: 0.5
        });
      });

      previousClassSlideImgContainer.forEach((item) => {
        gsap.to(item, {
          width: "75%",
          duration: 0.5
        });
      });
    });

    //Animating first container with text that comes in
    gsap.set(activeClasses[1], {x: 300});
    gsap.to(activeClasses[1], {x: 0, opacity: 1, duration: 0.8, delay: 0.2});

    //Animating second container with text that comes in
    gsap.set(activeClasses[3], {x: 300});
    gsap.to(activeClasses[3], {x: 0, opacity: 1, duration: 0.8, delay: 0.2});

    //Animating two containers with text that comes out
    gsap.to(previousClasses[1], {x: -600, opacity: 0, duration: 0.8, delay: 0});
    gsap.to(previousClasses[3], {x: -600, opacity: 0, duration: 0.8, delay: 0});

    slidingTimeline.to('.slider-container', {
      x: `-=${slideWidth + 15}vw`,
      duration: 1,
      onComplete() {
        setAnimationXValue(gsap.getProperty(this.targets()[0], "x"));
        setAnimationFinished(true);
        if (activeSlide < 4){
          setActiveSlide((prevActiveSlide) => prevActiveSlide+1);
        } else {
          setActiveSlide(2);
        }
      }
    });
  }

  let previousSlide = () => {
    if (slidingTimeline.isActive()) { return; }

    const activeClasses = document.querySelectorAll(`[data-slide-number="${slidesList[activeSlide-1]}"]`);
    const previousClasses = document.querySelectorAll(`[data-slide-number="${slidesList[activeSlide]}"]`);

    responsivenes.add("(min-width: 500px)", () => {
      const activeClassSlideImgContainer = document.querySelectorAll(`[data-slide-img-container-number="${slidesList[activeSlide-1]}"]`);
      const previousClassSlideImgContainer = document.querySelectorAll(`[data-slide-img-container-number="${slidesList[activeSlide]}"]`);

      activeClassSlideImgContainer.forEach((item) => {
        gsap.to(item, {
          width: "90%",
          duration: 0.5
        });
      });

      previousClassSlideImgContainer.forEach((item) => {
        gsap.to(item, {
          width: "66%",
          duration: 0.5
        });
      });
    });

    responsivenes.add("(max-width: 499px)", () => {
      const activeClassSlideImgContainer = document.querySelectorAll(`[data-slide-img-container-number="${slidesList[activeSlide-1]}"]`);
      const previousClassSlideImgContainer = document.querySelectorAll(`[data-slide-img-container-number="${slidesList[activeSlide]}"]`);

      activeClassSlideImgContainer.forEach((item) => {
        gsap.to(item, {
          width: "90%",
          duration: 0.5
        });
      });

      previousClassSlideImgContainer.forEach((item) => {
        gsap.to(item, {
          width: "75%",
          duration: 0.5
        });
      });
    });

    //Animating first container with text that comes in
    gsap.set(activeClasses[1], {x: -300});
    gsap.to(activeClasses[1], {x: 0, opacity: 1, duration: 0.8, delay: 0});

    //Animating second container with text that comes in
    gsap.set(activeClasses[3], {x: -300});
    gsap.to(activeClasses[3], {x: 0, opacity: 1, duration: 0.8, delay: 0});

    //Animating two containers with text that comes out
    gsap.to(previousClasses[1], {x: 300, opacity: 0, duration: 1, delay: 0});
    gsap.to(previousClasses[3], {x: 300, opacity: 0, duration: 1, delay: 0});

    slidingTimeline.to('.slider-container', {
      x: `+=${slideWidth + 15}vw`,
      duration: 1,
      onComplete() {
        setAnimationXValue(gsap.getProperty(this.targets()[0], "x"));
        setAnimationFinished(true);
        if (activeSlide > 2){
          setActiveSlide((prevActiveSlide) => prevActiveSlide-1);
        } else {
          setActiveSlide(4);
        }
      }
    });
  }

  let handleGesure = () => { 
      if (touchendX < touchstartX-50) {
        nextSlide();
      }
      if (touchendX > touchstartX+50) {
        previousSlide();
      }
  }

  return (
    <div className='container-fluid p-0 d-flex justify-content-start align-items-center overflow-x-hidden overflow-y-hidden position-relative' id='swiper' style={{minHeight: "75vh"}}>
      <div className='w-100'>
        <div className='d-flex flex-row justify-content-between align-items-center slider-container'>
          <div className="position-relative slider-object" id='slide-object' data-slide-number="2">
            <div className='col-11 col-sm-8' data-slide-img-container-number="2">
              <img src={SlideTwo} className='img-fluid' />
            </div>
            <CarouselTextContainer slideNumber={"2"} />
          </div>

          <div className="position-relative slider-object" data-slide-number="3">
            <div className='col-11 col-sm-8' data-slide-img-container-number="3">
              <img src={SlideThree} className='img-fluid' />
            </div>
            <CarouselTextContainer slideNumber={"3"} />
          </div>

          <div className="position-relative slider-object" data-slide-number="1">
            <div className='col-11 col-sm-8' data-slide-img-container-number="1">
              <img src={SlideOne} className='img-fluid' />
            </div>
            <CarouselTextContainer slideNumber={"1"} />
          </div>

          <div className="position-relative slider-object" data-slide-number="2">
            <div className='col-11 col-sm-8' data-slide-img-container-number="2">
              <img src={SlideTwo} className='img-fluid' />
            </div>
            <CarouselTextContainer slideNumber={"2"} />
          </div>

          <div className="position-relative slider-object" data-slide-number="3">
            <div className='col-11 col-sm-8' data-slide-img-container-number="3">
              <img src={SlideThree} className='img-fluid' />
            </div>
            <CarouselTextContainer slideNumber={"3"} />
          </div>

          <div className="position-relative slider-object" data-slide-number="1">
            <div className='col-11 col-sm-8' data-slide-img-container-number="1">
              <img src={SlideOne} className='img-fluid' />
            </div>
            <CarouselTextContainer slideNumber={"1"} />
          </div>

          <div className="position-relative slider-object">
            <div className='col-11 col-sm-8'>
              <img src={SlideTwo} className='img-fluid' />
            </div>
            <CarouselTextContainer slideNumber={"0"} />
          </div>
        </div>

        <div className='col-12 px-4 d-flex flex-row justify-content-between align-items-center position-absolute top-50 start-0 z-2 slider-controls'>
          <div><a className='text-black fs-1 cursor-pointer' id='previous-slide' onClick={(e) => previousSlide()}><ArrowLeft /></a></div>
          <div><a className='text-black fs-1 cursor-pointer' id='next-slide' onClick={(e) => nextSlide()}><ArrowRight /></a></div>
        </div>
      </div>
    </div>
  )
}

export default Carousel