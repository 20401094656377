import React, {useEffect, useState} from 'react';
import { useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import i18next from 'i18next';
import axios from 'axios';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import '../static/css/menuPage.css';

import LoadingAnimation from '../assets/gif/loading-animation.gif';
import  SaveButton from '../assets/menu-page/saveButtonSVG.svg';
import MenuContent from '../components/MenuContent';

import { ReactComponent as CloseSelectedItemsIcon } from '../assets/menu-page/closeSelectedItemsWindow.svg';
import { ReactComponent as SaveIconNoBack } from '../assets/menu-page/saveButtonNoBackground.svg';
import { ReactComponent as Logo } from '../assets/oazaLogoBlack.svg';

const MenuContentLoading = () => {
    return (
      <div className='col-12 rounded-5 bg-white position-absolute top-0 left-0 z-3 d-flex justify-content-center align-items-center' style={{height: "100%"}}>
          <div className='col-2'>
              <img src={LoadingAnimation} className='img-fluid' />
          </div>
      </div>
    )
}

// Selected Items Modal Window
const SelectedMenuItems = ({onClose}) => {
    const [selectedItems, setSelectedItems] = useState([]);

    const { t, i18n } = useTranslation();

    useEffect(() => {
        const cookieData = Cookies.get('selectedItems');
        axios.post('/api/menu/selected-items/', {selectedItems: JSON.parse(cookieData)}).then((response) => {
            setSelectedItems(response.data);
        });

        gsap.set('#selected-items-container', {y: "130%"});
        gsap.to('#selected-items-container', {
            y: 0,
            duration: 0.3,
            delay: 0.2
        });

        gsap.set('#selected-items', {x: "-100%"});
        gsap.to('#selected-items', {
            x: 0,
            duration: 0.4,
            delay: 0.5
        });

    }, [onClose])

    const hideModal = () => {
        gsap.to('#selected-items-container', {
            y: "130%",
            duration: 0.3,
            onComplete: (self) => {
                onClose(false);
            }
        });
    }

  return (
    <div className='container-fluid bg-dark bg-opacity-50 z-3 position-absolute top-0 d-flex justify-content-center align-items-center' style={{height: "100vh"}}>
        <div className='col-xl-6 h-75 bg-white rounded-5 p-3 position-relative d-flex flex-column align-items-stretch overflow-hidden menu-page-selected-items-block' id='selected-items-container'>
            <div className='col-12 d-flex justify-content-end my-4'>
                <a className='cursor-pointer' onClick={(e) => {hideModal(); document.body.style.overflow = "visible";}}><CloseSelectedItemsIcon /></a>
            </div>
            
            <div className='overflow-y-scroll menu-page-selected-items-container'>
                <div id='selected-items'>
                    {selectedItems.map((selectedItem, key) => (
                        <div key={key}>
                            <div key={key} className="col-12 py-4 d-flex flex-row justify-content-evenly border-bottom border-1 border-secondary-light">
                                <div className="col-7 d-flex flex-column justify-content-evenly">
                                    <p className="fs-2 fw-normal m-0">{i18n.language === 'en' ? selectedItem.translations?.en?.item_name : selectedItem.translations?.uk?.item_name}</p>
                                    <div>
                                        <h3>{selectedItem.item_price} UAH</h3>
                                    </div>
        
                                    {/* <div className="col-3 my-3">
                                        <a className="cursor-pointer"><img src={SaveButton} className="img-fluid"/></a>
                                    </div> */}
                                </div>
                                <div className="col-3">
                                    <img src={selectedItem.item_image} className="img-fluid" />
                                </div>
                            </div>
                        </div>
                    ))}

                    {selectedItems.length == 0 ? <h1>No items yet...</h1> : null}
                </div>
            </div>
        </div>
    </div>
  )
}

// Main Window with Menu Content
const MenuContentPage = () => {
    gsap.registerPlugin(ScrollTrigger);

    const { t, i18n } = useTranslation();
    const currentLanguageCode = Cookies.get('i18next') || 'ua';

    const [menuLoading, setMenuLoading] = useState(true);

    const location = useLocation();
    const [isSelectedItemsModalOpen, setIsSelectedItemsModalOpen] = useState(false);
    const [categories, setCategories] = useState([]);
    const [choosenCategory, setChoosenCategory] = useState(location.state?.categoryData?.id);
    const [choosenCategoryName, setChoosenCategoryName] = useState(location.state?.categoryData?.translations.en.category_name);
    const [choosenCategorySubcategories, setChoosenCategorySubcategories] = useState(location.state?.categoryData?.subcategories);
    const [activeSubcategory, setActiveSubcategory] = useState(location.state?.categoryData?.subcategories[0].id);
    
    useEffect(() => {
        if (!location.state?.categoryData || !location.state?.categoryData) {
            window.location.href = "/menu";
        }

        axios.get('/api/menu/categories/').then((response) => {
            setCategories(response.data);
        });

        document.getElementById('selected-items-header-button').addEventListener("mouseover", function (e) { 
            gsap.to('#vector-black', {strokeDashoffset: 0, duration: 0.3});
            gsap.to('#selected-items-header-button', {scale: 1.03, duration: 0.3});
        })

        document.getElementById('selected-items-header-button').addEventListener("mouseout", function (e) { 
            gsap.to('#vector-black', {strokeDashoffset: 100, duration: 0.3});
            gsap.to('#selected-items-header-button', {scale: 1, duration: 0.3});
        })
    }, []);

    const deleteAllScrollTriggers = () => {
        const allScrollTriggers = ScrollTrigger.getAll();
        allScrollTriggers.forEach(scrollTrigger => {
          scrollTrigger.kill();
        });
    };

  return (
    <div className='position-relative z-3 bg-white d-flex flex-column align-items-center'>
        {isSelectedItemsModalOpen ? <SelectedMenuItems onClose={setIsSelectedItemsModalOpen} /> : null }

        <div className='col-12 d-flex justify-content-center mb-5 z-2'>
            <div className='col-11 col-md-8 pt-3 d-flex flex-wrap justify-content-between align-items-center row-gap-3 header-galaxy-fold-flex-properties'>
                <div className='col-xsm-4 col-3 col-md-2'>
                    <a href='/' className='w-100'><Logo /></a>
                </div>

                <div className='col-xsm-12 col-7 col-md-4 col-xl-3 col-xxl-2 d-flex justify-content-between column-gap-2'>
                    <a className='text-dark py-3 py-md-2 px-3 border border-light-secondary border-1 rounded-4 d-flex flex-row align-items-center cursor-pointer' onClick={(e) => {setIsSelectedItemsModalOpen(true); document.body.style.overflow = "hidden";}} id='selected-items-header-button'><SaveIconNoBack /> <span className='ms-2'>Вибрані</span></a>
                
                    <div className="dropdown">
                        <button className="btn btn-secondary border border-light-secondary border-1 rounded-4 py-md-2 py-3 bg-transparent text-dark fs-5 dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            {currentLanguageCode}
                        </button>
                        <ul className="dropdown-menu">
                            <li><a className="dropdown-item" onClick={(e) => {i18next.changeLanguage('ua'); window.location.href = "/menu";}}>UA</a></li>
                            <li><a className="dropdown-item" onClick={(e) => {i18next.changeLanguage('en'); window.location.href = "/menu";}}>EN</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div className='col-12 col-lg-10 col-xl-9 col-xxl-8 position-relative  d-flex flex-column flex-md-row justify-content-around'>
            {menuLoading ? <MenuContentLoading /> : null }

            <div className='col-12 col-md-2 menu-content-page-categories-and-subcategories-container-mobile'>
                {window.innerWidth > 768 ?
                    <div className='menu-content-page-categories-and-subcategories-container'>
                        {categories.map((category, key) => (
                            <div key={key} className='col-12'>
                                <div>
                                    <a className='text-dark fs-2 fw-semibold cursor-pointer' onClick={(e) => {deleteAllScrollTriggers(); setChoosenCategory(category.id); setChoosenCategorySubcategories(category.subcategories); window.scrollTo(0, 0); setMenuLoading(true);}}>{i18n.language === 'en' ? category.translations?.en?.category_name : category.translations?.uk?.category_name}</a>
                                </div>

                                <div className={`col-12 d-flex flex-column mb-4 ${choosenCategory == category.id ? '' : 'main-menu-page-category-sections-container-hidden'}`}>
                                    {category.subcategories.map((subcategory, key) => (
                                        <a key={key} className={`fs-5 fw-medium w-100 ps-3 pe-5 py-3 rounded-3 cursor-pointer ${activeSubcategory == subcategory.id ? 'active-subcategory' : 'inactive-subcategory'}`} onClick={(e) => {document.getElementById(`${subcategory.translations.en.subcategory_name}-container`).scrollIntoView();}}>{i18n.language === 'en' ? subcategory.translations.en.subcategory_name : subcategory.translations.uk.subcategory_name}</a>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                :
                    <div className='d-flex flex-column align-items-center pt-4'>
                        <div className="dropdown">
                            <button className="btn btn-secondary bg-transparent text-dark fs-5 dropdown-toggle px-5" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                {choosenCategoryName}
                            </button>
                            <ul className="dropdown-menu">
                                {categories.map((category, key) => (
                                    <li key={key}><a className="dropdown-item" onClick={(e) => {deleteAllScrollTriggers(); setChoosenCategory(category.id); setChoosenCategoryName(category.translations.en.category_name); setChoosenCategorySubcategories(category.subcategories); window.scrollTo(0, 0); setMenuLoading(true);}}>{i18n.language === 'en' ? category.translations?.en?.category_name : category.translations?.uk?.category_name}</a></li>
                                ))}
                            </ul>
                        </div>

                        <div className='col-12 overflow-x-scroll d-flex flex-row column-gap-4 my-3 ps-3 menu-content-page-subcategories-mobile-container'>
                            {choosenCategorySubcategories.map(subcategory => (
                                <a className={`fw-medium fs-5 px-3 py-1 border border-1 border-secondary rounded-3 ${activeSubcategory == subcategory.id ? 'active-subcategory' : 'inactive-subcategory'}`} onClick={(e) => {document.getElementById(`${subcategory.translations.en.subcategory_name}-container`).scrollIntoView();}}>{i18n.language === 'en' ? subcategory.translations.en.subcategory_name : subcategory.translations.uk.subcategory_name}</a>
                            ))}
                        </div>
                    </div>
                }
            </div>

            <div className='col-12 col-md-9'>
                <MenuContent category={choosenCategory} subcategories={choosenCategorySubcategories} activeCategory={setActiveSubcategory} pageLoaded={setMenuLoading} />
                <div style={{height: "60vh"}}></div>
            </div>

        </div>

    </div>
  )
}

export default MenuContentPage