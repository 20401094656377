import React, {useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger'

import '../static/css/skiRentalPage.css';

import { ReactComponent as CloseModal } from '../assets/closeMenu.svg';

import HeroImage from '../assets/img/ski-rental-page/ski-rental-page-hero-section-image.png';
import SnowboardImage from '../assets/img/ski-rental-page/ski-rental-page-snowboard.png';
import SkiImage from '../assets/img/ski-rental-page/ski-rental-page-ski.png';
import SafetyImage from '../assets/img/ski-rental-page/ski-rental-page-safety.png';

const RentalModalWindow = ({title, description, onClose}) => {

  return (
    <div className='container-fluid bg-dark bg-opacity-75 position-fixed top-0 start-0 d-flex justify-content-center align-items-center' style={{height: "100dvh"}}>
        <div className='col-12 col-md-10 col-lg-8 col-xxl-6 bg-color-dark-skin h-75 px-4 pt-5'>
            <div className='col-12 d-flex justify-content-end'><a className='cursor-pointer' onClick={(e) => onClose(false)}><CloseModal /></a></div>
            <h1 className='text-white text-center mb-4'>{title}</h1>
            <p className='text-white text-center'>{description}</p>
        </div>
    </div>
  )
}

const SkiRentalPage = () => {
    gsap.registerPlugin(ScrollTrigger);

    const { t, i18n } = useTranslation();

    const [rentalEquipmentInfo, setRentalEquipmentInfo] = useState([]);
    let [isOpenModal, setIsOpenModal] = useState(false),
        [modalEquipmentTitle, setModalEquipmentTitle] = useState(''),
        [modalEquipmentDescription, setModalEquipmentDescription] = useState('');
    

    useEffect(() => {
        axios.get('/api/rental-equipment/').then((response) => {
            setRentalEquipmentInfo(response.data);
        });

        // gsap.to("#hero-image", {
        //     y: -150,
        //     scrollTrigger: {
        //         trigger: "#ski-rental-items-section",
        //         start: "top bottom",
        //         end: "top 25%",
        //         scrub: true
        //     }
        // })
    }, []);

  return (
    <div className='bg-color-dark' style={{minHeight: "100vh"}}>
        <section className='container-fluid bg-color-dark d-flex justify-content-center' style={{minHeight: "100vh"}}>
            <div className='col-11 d-flex flex-column header-offset'>
                <div className='col-12 col-xl-9'>
                    <h1 className='text-color-dark-skin text-center text-xl-start text-title-2 fw-bold'>Немає спорядження?<br />Ми допоможемо!</h1>
                </div>

                <div className='col-12 pb-5 d-flex flex-column flex-xl-row justify-content-between align-items-center align-items-xl-stretch'>
                    <div className='col-12 col-md-11 col-xl-5 my-4 d-flex flex-column flex-lg-row flex-xl-column justify-content-between align-items-center align-items-xl-start'>
                        <p className='col-lg-8 col-xl-12 text-white text-center text-lg-start text-responsive-1'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam maximus sapien ligula, in efficitur sapien commodo viverra. Aliquam tincidunt convallis sem sit amet lobortis. Nunc ullamcorper varius eleifend. Nam a ex diam. Proin eget ipsum dolor.</p>
                    
                        <a className='text-white fs-3 px-4 py-3 border border-2 border-color-skin'>Асортимент</a>
                    </div>

                    <div className='col-10 col-md-8 col-xl-5'>
                        <img src={HeroImage} className='img-fluid' id='hero-image' />
                    </div>
                </div>
            </div>
        </section>

        {/* <section className='d-flex justify-content-center' id='ski-rental-items-section'>
            <div className='col-xl-11 d-flex flex-wrap row-gap-5 header-offset'>
                {rentalEquipmentInfo.map((equipment, key) => (
                    <div className='col-md-6 col-lg-4 position-relative rental-item-container'>
                        <div className='col-12 rental-item-image-container'><img src={equipment.equipment_image} className='img-fluid' /></div>
                        <p className='text-white text-center'>{i18n.language === 'en' ? equipment.translations?.en?.title : equipment.translations?.uk?.title}</p>

                        <div className='position-absolute top-50 start-50 translate-middle rental-item-info'>
                            <a className='bg-color-light-skin text-dark fs-2 fw-medium px-4 py-3 cursor-pointer' onClick={(e) => {setModalEquipmentTitle(equipment.translations.en.title); setModalEquipmentDescription(equipment.translations.en.description); setIsOpenModal(true)}}>Докладніше</a>
                        </div>
                    </div>
                ))}
            </div>
        </section>

        {isOpenModal ? <RentalModalWindow title={modalEquipmentTitle} description={modalEquipmentDescription} onClose={setIsOpenModal} /> : NaN} */}
    </div>
  )
}

export default SkiRentalPage