import React, {useEffect} from 'react';
import gsap from 'gsap';

import Footer from '../components/Footer';

import '../static/css/restaurantPage.css';

import CuisineSectionImage from '../assets/img/restaurant-page/restaurant-page-cuisine-section-image.jpg';
import EventsSectionImage from '../assets/img/restaurant-page/restaurant-page-events-section-image.jpg';

const RestaurantPage = () => {

    useEffect(() => {
        gsap.fromTo(".restaurant-page-hero-section-images", {
            backgroundSize: "200%"
        }, {
            backgroundSize: "100%",
            duration: 1,
            delay: 0.3
        })
    }, [])

  return (
    <div>
        <section className='container-fluid bg-dark p-0 position-relative d-flex justify-content-center' style={{height: "100vh"}}>
            <div className='col-11 header-offset'>
                <div className='col-lg-10 col-xxl-9'>
                    <h1 className='text-color-dark-skin text-center text-md-start text-title-2 fw-bold'>Відчувайте насолоду у кожному шматочку.</h1>
                    <p className='text-md-start font-mont-light col-12 col-md-10 col-xl-7 mt-3 text-white text-responsive-1 text-spread'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam maximus sapien ligula, in efficitur sapien commodo viverra. Aliquam tincidunt convallis sem sit amet lobortis. Nunc ullamcorper varius eleifend. Nam a ex diam. Proin eget ipsum dolor.</p>
                </div>

                <div className='col-12 h-50 position-absolute d-flex flex-row justify-content-center column-gap-5 overflow-x-hidden restaurant-page-hero-section-images-section'>
                    <div className='col-6 col-md-5 col-lg-3 col-xxl-2 restaurant-page-hero-section-images'></div>
                    <div className='col-6 col-md-5 col-lg-3 col-xxl-2 restaurant-page-hero-section-images'></div>
                    <div className='col-6 col-md-5 col-lg-3 col-xxl-2 restaurant-page-hero-section-images'></div>
                    <div className='col-6 col-md-5 col-lg-3 col-xxl-2 restaurant-page-hero-section-images'></div>
                    <div className='col-6 col-md-5 col-lg-3 col-xxl-2 restaurant-page-hero-section-images'></div>
                </div>
            </div>
        </section>

        <section className='container-fluid d-flex flex-row justify-content-center align-items-end my-5 restaurant-page-cuisine-section-container'>
            <div className='col-11 d-flex flex-column flex-xl-row justify-content-center align-items-center align-items-xl-stretch column-gap-5'>
                <div className='col-12 col-md-10 col-xl-5 d-flex flex-column justify-content-start'>
                    <h1 className='text-dark text-title-2 text-title-md-4 fw-bold'>Гармонія краси та смаку</h1>
                    <p className='text-dark text-responsive-1 text-spread-md text-spread'>Відвідавши наш комплекс, ви маєте можливість насолодитися стравами гуцульської, української та європейської кухні в найвисокогірнішому ресторані України. Скуштувати ароматний трав’яний чай на відкритій терасі, з якої відкривається неймовірний пейзаж на вершини навколишніх гір.</p>
                </div>

                <div className='col-12 col-md-10 col-xl-5'>
                    <img src={CuisineSectionImage} className='img-fluid' />
                </div>
            </div>
        </section>

        <section className='container-fluid bg-color-dark d-flex flex-row justify-content-center align-items-end py-5' style={{minHeight: "50vh"}}>
            <div className='col-11 d-flex flex-wrap justify-content-center column-gap-5 row-gap-5'>
                <div className='col-12'>
                    <h1 className='text-white text-title-2 text-title-md-3 text-center fw-bold'>Створюйте незабутні спогади разом з нами</h1>
                </div>

                <div className='col-12 col-md-10 col-xl-5'>
                    <img src={EventsSectionImage} className='img-fluid' />
                </div>

                <div className='col-12 col-md-10 col-xl-5 d-flex flex-column justify-content-between align-items-center align-items-lg-start'>
                    <p className='text-white text-responsive-1 text-spread-md'>Відвідавши наш комплекс, ви маєте можливість насолодитися стравами гуцульської, української та європейської кухні в найвисокогірнішому ресторані України. Скуштувати ароматний трав’яний чай на відкритій терасі, з якої відкривається неймовірний пейзаж на вершини навколишніх гір.</p>
                    <a href='/menu/' className='text-white fs-2 px-3 py-2 button-hover-right-fill-skin border border-2 border-color-skin menu-page-link-button'>Меню</a>
                </div>
            </div>
        </section>

        <Footer />
    </div>
  )
}

export default RestaurantPage