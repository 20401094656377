import React, {useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import gsap from 'gsap';

import ImageSlider from '../components/ImageSlider';

import CheckIcon from '../assets/img/check-grey-icon.png';
import { ReactComponent as ArrowBack } from '../assets/arrowToRightWithTail.svg';

import '../static/css/singleRoomPage.css';

const SingleRoomPage = () => {
    const { t, i18n } = useTranslation();

    let roomId = useParams();
    const [singleRoom, setSingleRoom] = useState([]);

    useEffect(() => {
        axios.get(`/api/rooms/${roomId['id']}`).then((response) => {
            setSingleRoom(response.data);
        });
    });

  return (
    <div className='bg-color-dark d-flex justify-content-center' style={{minHeight: "100vh"}}>
        <div className='col-11 col-md-10 col-xl-9 bg-white pb-5 pt-5 mb-5 d-flex flex-column align-items-center header-offset single-room-info-container'>
            <div className='col-11 h-auto m-0 mb-4 p-0'>
                <a href='/rooms/'><ArrowBack /></a>
            </div>
            <div className='col-11 col-md-10 col-xl-9 p-3 mb-5 d-flex flex-column flex-md-row justify-content-between align-items-center single-room-title-container'>
                <div className='col-12 col-md-8'>
                    <p className='text-white fs-2 font-mont-semibold m-0'>{i18n.language === 'en' ? singleRoom?.translations?.en?.name : singleRoom?.translations?.uk?.name}</p>
                    <p className='text-white text-responsive-1 font-mont-light'>Максимум 2 гості, з них максимум 2 дорослих</p>
                </div>

                <div className='col-12 col-md-3 d-flex justify-content-start justify-content-md-center align-items-start align-items-md-center'>
                    <p className='text-white fs-5 font-mont-light m-0'>{singleRoom?.price} {t('room_price_daily')}</p>
                </div>
            </div>

            <div className='col-11 col-md-10 col-xl-9 mt-4 d-flex flex-column flex-lg-row justify-content-between align-items-center align-items-lg-start row-gap-5'>
                <div className='col-11 col-lg-6 d-flex flex-column row-gap-4'>
                    <div className='w-100 rounded-4 overflow-hidden'>
                        <ImageSlider images={singleRoom?.images} />
                    </div>
                    
                    <div className='d-flex flex-wrap justify-content-start column-gap-3 row-gap-3'>
                        {singleRoom?.room_amenities?.map((amenity, key) => (
                            <div key={key} className='col-5 col-sm-5 col-md-3 col-lg-5 col-xxl-2 d-flex flex-row align-items-center'>
                                <div className='col-2 col-sm-2 col-md-3 col-xl-2 col-xxl-3'>
                                    <img src={`${amenity.icon_light ? amenity.icon_light : amenity.icon_dark}`} className='img-fluid' />
                                </div>
                                <p className='text-secondary fs-6 font-mont-light m-0 ms-2'>{i18n.language === 'en' ? amenity.translations.en.name : amenity.translations.uk.name}</p>
                            </div>
                        ))}
                    </div>

                    <p className='fs-5 font-mont-light'>{i18n.language === 'en' ? singleRoom?.translations?.en?.description : singleRoom?.translations?.uk?.description}</p>
                </div>

                <div className='col-11 col-lg-5 single-room-equipment-container d-flex flex-column align-items-center'>
                    <div className='col-8 pb-2 mt-4 mb-4 single-room-equipment-container-title'>
                        <p className='text-center fs-5 font-mont-light m-0'>Номер оснащений</p>
                    </div>

                    <div className='col-11 d-flex flex-wrap align-items-center ps-4 mt-3'>
                        {singleRoom?.room_equipment?.map((equipment, key) => (
                            <div key={key} className='col-6 pe-1 d-flex flex-row'>
                                <div className='col-1'>
                                    <img src={CheckIcon} className='img-fluid' />
                                </div>
                                <p className='text-dark-color fs-6 font-mont-light ms-2'>{i18n.language === 'en' ? equipment?.translations?.en?.name : equipment?.translations?.uk?.name}</p>
                            </div>
                        ))}
                    </div>

                    <div className='col-12 mt-auto'>
                        <p className='font-mont-light ms-4'><span className='font-mont-semibold'>Smoking</span>: NO SMOKING</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default SingleRoomPage