import React from 'react';
import { useTranslation } from 'react-i18next';

import '../static/css/menu.css';

const Menu = () => {

  const { t } = useTranslation();

  return (
    <div className='container-fluid menu-container position-fixed align-self-start z-1' style={{height: "100vh"}}>
        <div className='menu h-100 position-fixed end-0 d-flex justify-content-end align-items-center'>
            <div className='col-8 h-50 d-flex flex-column justify-content-between align-items-center'>
                <a href='https://www.booking.com/hotel/ua/oaza-iasinia.cs.html#room_110467301' target="_blank" className='text-white fs-1 font-montserrat-bold menu-link-1'>{t("menu_link_booking")}</a>
                <a href='/about-hotel/' className='text-white fs-1 font-montserrat-bold menu-link-2'>{t("menu_link_hotel")}</a>
                <a href='/rooms/' className='text-white fs-1 font-montserrat-bold menu-link-3'>{t("menu_link_rooms")}</a>
                <a href='/restaurant/' className='text-white fs-1 font-montserrat-bold menu-link-4'>{t("menu_link_restaurant")}</a>
                <a href='/ski-rental/' className='text-white fs-1 font-montserrat-bold menu-link-5'>{t("menu_link_rental")}</a>
            </div>
        </div>
    </div>
  )
}

export default Menu