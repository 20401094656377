import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

const ImageSlider = ({images}) => {

  return (
    <Carousel infiniteLoop={true} showIndicators={false} showThumbs={false} showStatus={false} dynamicHeight={true}>
        {images?.map((image, key) => (
            <div key={key}>
                <img src={`${image.image}`} />
            </div>
        ))}
    </Carousel>
  )
}

export default ImageSlider