import React, {useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import * as ReactDOM from 'react-dom/client';
import { Link } from 'react-router-dom';
import axios from 'axios';

import '../static/css/menuPage.css';

import { ReactComponent as ArrowRight } from '../assets/menu-page/arrowRight.svg';
import { ReactComponent as Logo } from '../assets/oazaLogo.svg';

const MenuPage = () => {
    const [categories, setCategories] = useState([]);
    const [menuItems, setMenuItems] = useState([]);

    const { t, i18n } = useTranslation();

    useEffect(() => {
        axios.get('/api/menu/categories/').then((response) => {
            setCategories(response.data);
        });
    }, []);


  return (
    <div className='position-relative z-3 bg-white'>
        <div className='col-12 d-flex justify-content-end menu-categories-page-hero-section'>
            <div className='col-7'>
                <Link reloadDocument to="/">
                    <div className='col-3'>
                        <Logo />
                    </div>
                </Link>
            </div>
        </div>

        <div className='col-12 d-flex justify-content-center position-relative'>
            <div className='col-11 col-md-9 col-xl-8 col-xxl-6 pb-5 bg-white d-flex justify-content-center menu-categories-page-category-container' id='menu-container'>
                <div className='col-10'>
                    <div className='my-5 py-4 menu-categories-page-category-container-heading-text-container'>
                        <h1 className='ms-5 fw-bold menu-categories-page-category-container-heading-text'>Розділи меню:</h1>
                    </div>

                    {/* {categories.map((category, key) => (
                        <Link key={key} to={"/menu/content"} state={{categoryData: category}}>
                            <div className='my-4 border border-2 d-flex flex-row justify-content-between align-items-center cursor-pointer menu-categories-page-category-item'>
                                <h1 className='text-dark ms-5 mb-0 fw-bold menu-categories-page-category-item-text'>{i18n.language === 'en' ? category.translations?.en?.category_name : category.translations?.uk?.category_name}</h1>
                                <ArrowRight className="me-5 menu-categories-page-category-item-arrow" /> 
                            </div>
                        </Link>
                    ))} */}

                    <Link to={"/static/menuPDF/regular-menu.pdf"} target="_blank">
                        <div className='my-4 border border-2 d-flex flex-row justify-content-between align-items-center cursor-pointer menu-categories-page-category-item'>
                            <h1 className='text-dark ms-5 mb-0 fw-bold menu-categories-page-category-item-text'>Страви</h1>
                            <ArrowRight className="me-5 menu-categories-page-category-item-arrow" /> 
                        </div>
                    </Link>

                    <Link to={"/static/menuPDF/bar-menu.pdf"} target="_blank">
                        <div className='my-4 border border-2 d-flex flex-row justify-content-between align-items-center cursor-pointer menu-categories-page-category-item'>
                            <h1 className='text-dark ms-5 mb-0 fw-bold menu-categories-page-category-item-text'>Напої</h1>
                            <ArrowRight className="me-5 menu-categories-page-category-item-arrow" /> 
                        </div>
                    </Link>

                    <Link to={"/static/menuPDF/breakfast-menu.pdf"} target="_blank">
                        <div className='my-4 border border-2 d-flex flex-row justify-content-between align-items-center cursor-pointer menu-categories-page-category-item'>
                            <h1 className='text-dark ms-5 mb-0 fw-bold menu-categories-page-category-item-text'>Сніданки</h1>
                            <ArrowRight className="me-5 menu-categories-page-category-item-arrow" /> 
                        </div>
                    </Link>

                    <Link to={"/static/menuPDF/wine-menu.pdf"} target="_blank">
                        <div className='my-4 border border-2 d-flex flex-row justify-content-between align-items-center cursor-pointer menu-categories-page-category-item'>
                            <h1 className='text-dark ms-5 mb-0 fw-bold menu-categories-page-category-item-text'>Винна карта</h1>
                            <ArrowRight className="me-5 menu-categories-page-category-item-arrow" /> 
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    </div>
  )
}

export default MenuPage