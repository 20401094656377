import React, { useEffect } from 'react';
import gsap from 'gsap';

import '../static/css/loadingScreen.css';

import {ReactComponent as OazaLogoStroked} from '../assets/oaza-logo-stroked.svg';

import logoLight from '../assets/gif/logoLight.gif';

const LoadingScreen = () => {
    useEffect(() => {
        gsap.to('#loading-screen', {y: "-100%", duration: 0.5, delay: 2.8});
        // gsap.to('#loading-bar', {width: "100%", duration: 1.9, delay: 0});
        // gsap.to("#o-letter", {strokeDashoffset: 0, duration: 2, delay: 0.19});
        // gsap.to("#a-letter-one", {strokeDashoffset: 0, duration: 2, delay: 0.2});
        // gsap.to("#a-letter-two", {strokeDashoffset: 0, duration: 2, delay: 0.18});
        // gsap.to("#mountains", {strokeDashoffset: 0, duration: 2, delay: 0.2});
        // gsap.to("#z-letter", {strokeDashoffset: 0, duration: 2, delay: 0.15});
    })

  return (
    <div className='container-fluid bg-color-dark d-flex flex-column justify-content-center align-items-center position-fixed top-0 z-10 overflow-hidden' id='loading-screen' style={{height: "100vh"}}>
        {/* <div><OazaLogoStroked /></div> */}
        <div className='col-12 col-md-10 col-lg-8 col-xl-5'><img src={logoLight} className='img-fluid' /></div>
        {/* <div className='bg-secondary col-9 rounded-5' style={{height: "5px"}}>
          <div className="bg-white rounded-5" style={{width: "0%", height: "5px"}} id='loading-bar'>-</div>
        </div> */}
    </div>
  )
}

export default LoadingScreen