import React, {useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import * as ReactDOM from 'react-dom/client';
import Cookies from 'js-cookie';
import axios from 'axios';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import  SaveButton from '../assets/menu-page/saveButtonSVG.svg';
import  SaveButtonActive from '../assets/menu-page/saveButtonActiveSVG.svg';

const MenuContent = ({category, subcategories, activeCategory, pageLoaded}) => {
    gsap.registerPlugin(ScrollTrigger);

    const { t, i18n } = useTranslation();

    const [menuItems, setMenuItems] = useState([]);
    const [subcategoriesLoaded, setSubcategoriesLoaded] = useState('');
    const [cookiesItemsList, setCookiesItemsList] = useState(JSON.parse(Cookies.get('selectedItems')));
    const [pageDidMount, setPageDidMount] = useState('');

    useEffect(() => {
        if(!category || !subcategories) {
            window.location.href = "/menu";
        }
        setSubcategoriesLoaded('loaded');
    }, []);

    //Updating Cookies
    useEffect(() => {
        // if (!cookiesItemsList.length) { return }
        Cookies.set('selectedItems', JSON.stringify(cookiesItemsList));
    }, [cookiesItemsList])

    //Creating Subcategory Containers
    useEffect(() => {
        let setCategoriesContainers = async () => {
            axios.get(`/api/menu/items/${category}`).then((response) => {
                setMenuItems(response.data);
            });

            const menuContentContainer = ReactDOM.createRoot(
                document.getElementById('menu-content-container')
            ); 

            let subcategoriesContainer = [];

            subcategories.map((subcategory, key) => {
                subcategoriesContainer.push(
                    <div key={key} id={`${subcategory.translations.en.subcategory_name}-container`}>
                        <div className='col-12 py-3 border-bottom border-2 border-secondary-light'>
                            <h1 className='text-center'>{i18n.language === 'en' ? subcategory.translations?.en?.subcategory_name : subcategory.translations?.uk?.subcategory_name}</h1>
                        </div>

                        <div id={subcategory.translations.en.subcategory_name}>
                            
                        </div>
                    </div>
                )
                menuContentContainer.render(subcategoriesContainer);
            });

            setSubcategoriesLoaded(category)
        }

        setCategoriesContainers();
    }, [subcategories, category]);

    // Adding Dished and Drinks to Subcategory Container
    useEffect(() => {
        if (!subcategoriesLoaded == '') {
            subcategories.map(subcategory => {
                const menuItemCategoryContainer = ReactDOM.createRoot(
                    document.getElementById(subcategory.translations.en.subcategory_name)
                );

                let ItemHtmlDataContainer = [];

                menuItems.map((menuItem, key) => {
                    if (menuItem.item_subcategory.translations.en.subcategory_name == subcategory.translations.en.subcategory_name) {
                        ItemHtmlDataContainer.push(
                            <div key={key} className="col-12 py-4 d-flex flex-row justify-content-evenly border-bottom border-1 border-secondary-light">
                                <div className="col-8 col-md-7 d-flex flex-column justify-content-evenly">
                                    <p className="fs-2 fw-normal m-0">{i18n.language === 'en' ? menuItem.translations?.en?.item_name : menuItem.translations?.uk?.item_name}</p>
                                    <div>
                                        <h3>{menuItem.item_price} UAH</h3>
                                    </div>
        
                                    <div className="col-3 my-3">
                                    {cookiesItemsList.includes(menuItem.id) ? <a className="cursor-pointer" onClick={(e) => RemoveItemFromSelectedItems(menuItem.id)}><img src={SaveButtonActive} className="img-fluid"/></a> : <a className="cursor-pointer" onClick={(e) => AddItemToSelectedItems(menuItem.id)}><img src={SaveButton} className="img-fluid"/></a>}
                                    </div>
                                </div>
                                <div className="col-3">
                                    <img src={menuItem.item_image} className="img-fluid" />
                                </div>
                            </div>
                        );
                    }
                });
                menuItemCategoryContainer.render(ItemHtmlDataContainer);              
            });

            setPageDidMount(category);
            pageLoaded(false);
        }
    }, [menuItems, cookiesItemsList]);

    //Creating ScrollTriggers for Changing Active Subcategory
    useEffect(() => {
        if (!pageDidMount == '') {
            activeCategory(subcategories[0].id);
            subcategories.forEach(subcategory => {
                ScrollTrigger.create({
                    trigger: `#${subcategory.translations.en.subcategory_name}-container`,
                    start: "top 15%",
                    end: "bottom 15%",
                    scrub: true,
                    onEnter: (self) => {
                        activeCategory(subcategory.id);
                    },
                    onEnterBack: (self) => {
                        activeCategory(subcategory.id);
                    }
                });
            })
        }
    }, [pageDidMount]);

    let AddItemToSelectedItems = (item) => {
        setCookiesItemsList(previousList => [...previousList, item]);
    }

    let RemoveItemFromSelectedItems = (item) => {
        console.log(cookiesItemsList.length);
        if (cookiesItemsList.length > 2) {
            const updatedList = cookiesItemsList.filter(previousList => previousList !== item);

            setCookiesItemsList(updatedList);
        } else {
            setCookiesItemsList([]);
        }
    }

  return (
    <div className='col-12 border border-2 border-secondary-light rounded-5' id='menu-content-container'>

    </div>
  )
}

export default MenuContent