import React from 'react'

const BookingComplition = () => {
  return (
    <div className='container' style={{height: "100vh"}}>
        <div className='col-12'>
            <h1 className='text-center fw-bold font-montserrat-bold'>Order Completed</h1>
            <p className='text-center fs-4 font-montserrat-regular'>Check your E-mail for the details of the order.</p>

            <a href='/' className='fs-2 text-dark text-center font-montserrat-bold bg-color-skin bg-color-golden-skin px-3 py-2'>Go Home</a>
        </div>
    </div>
  )
}

export default BookingComplition