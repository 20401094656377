import React, {useEffect, useState} from 'react';
import cookies from 'js-cookie';
import i18next from 'i18next';
import axios from 'axios';
import gsap from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";

import '../static/css/header.css';

import Menu from './Menu';

import { ReactComponent as Logo } from '../assets/oazaLogo.svg';
import { ReactComponent as Burger } from '../assets/burgerMenu.svg';
import { ReactComponent as Close } from '../assets/closeMenu.svg';

const Header = () => {
    gsap.registerPlugin(ScrollTrigger);

    const currentLanguageCode = cookies.get('i18next') || 'ua'

    useEffect(() => {
        let headerAnim = gsap.timeline({
            scrollTrigger: {
                trigger: ".header",
                start: "center top",
                end: "250% top",
                scrub: true
            }
        });
        headerAnim.to(".header", {backgroundColor: "#272829"});

        const customSelect = document.querySelector(".custom-select");
        const selectBtn = document.querySelector(".select-button");

        const selectedValue = document.querySelector(".selected-value");
        const optionsList = document.querySelectorAll(".select-dropdown li");

        // add click event to select button
        selectBtn.addEventListener("click", () => {
            console.log(customSelect);
            // add/remove active class on the container element
            if (document.getElementsByClassName("active").length > 0) {
                customSelect.classList.remove("active");
            } else {
                customSelect.classList.add("active");
            }
            // update the aria-expanded attribute based on the current state
            selectBtn.setAttribute("aria-expanded", selectBtn.getAttribute("aria-expanded") === "true" ? "false" : "true");
        });

        optionsList.forEach((option) => {
            function handler(e) {
                // Click Events
                if (e.type === "click" && e.clientX !== 0 && e.clientY !== 0) {
                selectedValue.textContent = this.children[1].textContent;
                customSelect?.classList.remove("active");
                }
            }

            option?.addEventListener("click", handler);
        });
    }, []);

    let showMenu = () => {
        let mobileAnimation = gsap.matchMedia()
        document.body.style.overflow = "hidden";
        gsap.to(".header", {backgroundColor: "#272829"});
        gsap.to('.close-menu-button', {
            visibility: "visible",
            position: "relative"
        });

        gsap.to('.show-menu-button', {
            visibility: "hidden",
            position: "absolute"
        });

        gsap.to(".menu-container", {
            width: "100%",
            duration: 0.2
        });

        gsap.to(".menu", {
            width: "50vw",
            duration: 0.5
        });

        gsap.to(".menu-link-1", {x: 0, duration: 0.5});
        gsap.to(".menu-link-2", {x: 0, duration: 0.5, delay: 0.1});
        gsap.to(".menu-link-3", {x: 0, duration: 0.5, delay: 0.2});
        gsap.to(".menu-link-4", {x: 0, duration: 0.5, delay: 0.3});
        gsap.to(".menu-link-5", {x: 0, duration: 0.5, delay: 0.4});

        mobileAnimation.add("(max-width: 500px)", () => {
            gsap.to(".menu", {
                width: "65vw",
                duration: 0.5
            });

            gsap.to(".menu-link-1", {x: -25, duration: 0.5});
            gsap.to(".menu-link-2", {x: -25, duration: 0.5, delay: 0.1});
            gsap.to(".menu-link-3", {x: -25, duration: 0.5, delay: 0.2});
            gsap.to(".menu-link-4", {x: -25, duration: 0.5, delay: 0.3});
            gsap.to(".menu-link-5", {x: -25, duration: 0.5, delay: 0.4});
        });
    };

    let hideMenu = () => {
        document.body.style.overflow = "visible";
        gsap.to('.close-menu-button', {
            visibility: "hidden",
            position: "absolute"
        });

        gsap.to('.show-menu-button', {
            visibility: "visible",
            position: "relative"
        });

        gsap.to(".menu-container", {
            width: "0",
            duration: 0.2
        });

        gsap.to(".menu", {
            width: "0",
            duration: 0.5
        });

        gsap.to(".menu-link-1", {x: "100%", duration: 0.5, delay: 0.2});
        gsap.to(".menu-link-2", {x: "100%", duration: 0.5, delay: 0.2});
        gsap.to(".menu-link-3", {x: "100%", duration: 0.5, delay: 0.1});
        gsap.to(".menu-link-4", {x: "100%", duration: 0.5, delay: 0.1});
        gsap.to(".menu-link-5", {x: "100%", duration: 0.5, delay: 0});
    };

  return (
    <div className='container-fluid position-fixed z-3 d-flex justify-content-center header'>
        <div className='col-11 col-lg-10 py-2 d-flex flex-row justify-content-between z-2'>
            <div className='col-6 d-flex flex-row'>
                <div className='col-5 col-md-3 col-lg-2 d-flex align-items-center'>
                    <a href='/' className='w-100'><Logo className="img-fluid" /></a>
                </div>
            </div>

            <div className='col-6 col-lg-7 col-xl-5 d-flex flex-row justify-content-evenly'>
                <div className='d-flex align-items-center'>
                    {/* <Phone /> */}
                    <a href='tel:+380679342515' className='text-white fs-4 font-libre-caslon header-phone-number'>+38 (067) 934 25 15</a>
                </div>

                <div className="custom-select">
                    <button
                        className="select-button"
                        role="combobox"
                        aria-labelledby="select button"
                        aria-haspopup="listbox"
                        aria-expanded="false"
                        aria-controls="select-dropdown"
                    >
                        <span className="text-white fs-4 selected-value font-mont-light">{currentLanguageCode.toUpperCase()}</span>
                        <span className="arrow"></span>
                    </button>
                    <ul class="select-dropdown border border-1 border-dark" role="listbox" id="select-dropdown">
                        <li role="option" onClick={(e) => i18next.changeLanguage('ua')}>
                            <input type="radio" id="github" name="social-account" />
                            <label htmlFor="github" className='font-mont-semibold'>UA</label>
                        </li>
                        <li role="option" onClick={(e) => i18next.changeLanguage('en')}>
                            <input type="radio" id="instagram" name="social-account" />
                            <label htmlFor="instagram" className='font-mont-semibold'>EN</label>
                        </li>
                    </ul>
                </div>

                <div className='d-flex align-items-center'>
                    <a className='cursor-pointer show-menu-button' onClick={(e) => showMenu()}><Burger /></a>
                    <a className='cursor-pointer close-menu-button' onClick={(e) => hideMenu()}><Close /></a>
                </div>
            </div>
        </div>

        <Menu />
    </div>
  )
}

export default Header