import React from 'react';
import gsap from 'gsap';

import ImageSlider from '../ImageSlider';

const BookingAvailableRoom = ({room, choosenRoom, startDate, endDate, amount, saveToDB, changeStage}) => {

    let chooseRoom = async () => {
        window.scrollTo(0, 0);
        let totalPrice = Math.round((endDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24)) * room.price
        amount(totalPrice);
        choosenRoom(room);
        saveToDB(room.id, totalPrice);
        changeStage(2);
        gsap.to("#progress-bar-colored", {strokeDashoffset: 1280, duration: 1, delay: 0.1});
        gsap.to("#stage-three", {fill: "#E2A971", duration: 0.5, delay: 0.9});
    }

  return (
    <div className='col-11 col-xxl-10 bg-color-dark my-5 p-3 py-5 d-flex flex-column justify-content-center align-items-center align-items-xl-stretch column-gap-5 row-gap-3'>
        <div className='d-flex flex-column flex-xl-row justify-content-center align-items-center align-items-xl-stretch column-gap-5 row-gap-3'>
            <div className='col-11 col-md-10 col-xl-6'>
                <ImageSlider images={room.images} />
            </div>
            <div className='col-11 col-md-10 col-xl-5 d-flex flex-column justify-content-between align-items-start row-gap-3'>
                <div>
                    <h1 className='text-white font-alegreya-sans-bold'>{room.name}</h1>
                    <p className='text-white font-alegreya-sans-regular text-responsive-1'>{room.description}</p>
                    
                    <div>
                        <p className='text-white font-alegreya-sans-medium fs-2 m-0'>{room.price}₴+ <span className='text-color-darker-skin fs-5 fw-normal'>/Ніч</span></p>
                    </div>
                </div>
            </div>
        </div>

        <div className='col-12 d-flex justify-content-center'>
            <a className='text-white fs-4 px-5 py-2 bg-color-light-skin border border-color-skin' onClick={(e) => chooseRoom()}>Обрати</a>
        </div>
    </div>
  )
}

export default BookingAvailableRoom