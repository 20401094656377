import React, {useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import '../static/css/homePage.css';

import FavoriteRooms from '../components/FavoriteRooms';
import Carousel from '../components/Carousel';
import Footer from '../components/Footer';

import AbooutHotel from '../assets/img/home-page/home-page-about-hotel-photo.jpg';

import { ReactComponent as WifiIcon } from '../assets/advantages/wifiIcon.svg';
import { ReactComponent as LiftIcon } from '../assets/advantages/lift.svg';
import { ReactComponent as LiftTwoIcon } from '../assets/advantages/lift2.svg';
import { ReactComponent as ParkingIcon } from '../assets/advantages/parking.svg';
import { ReactComponent as DishesIcon } from '../assets/advantages/dishes.svg';

const HomePage = () => {
  gsap.registerPlugin(ScrollTrigger);

  const { t, i18n } = useTranslation();

  const [favoriteRooms, setFavoriteRooms] = useState([]);

  useEffect(() => {
    axios.get('/api/favorite-rooms/').then((response) => {
      setFavoriteRooms(response.data);
    })

    const heroParallax = gsap.timeline({
      scrollTrigger: {
        trigger: '.hero-section',
        start: '20% top',
        end: 'bottom top',
        scrub: true
      }
    });
    heroParallax.to('.hero-section-background-image', {backgroundPosition: "50% 150%"});

    gsap.to('.hero-section-text', {
      scrollTrigger: {
        trigger: '.hero-section',
        start: '20% top',
        end: 'bottom top',
        scrub: true
      },
      y: 300,
      opacity: 0
    });

    gsap.set('.advantages-section-text-lines', {width: 0});
    gsap.to('.advantages-section-text-lines', {
      width: "100%",
      duration: 1,
      scrollTrigger: {
        trigger: '.advantages-section',
        start: "50% bottom",
        scrub: false
      }
    });

    gsap.fromTo('.carousel-title-text', {
      x: "-100%",
    }, {
      x: 0,
      duration: 1,
      delay: 0.3,
      scrollTrigger: {
        trigger: "#carousel-section",
        start: "10% bottom",
        scrub: false
      }
    });
  }, [])

  return (
    <div>
        <section className='container-fluid m-0 p-0 position-relative d-flex flex-column justify-content-center align-items-center z-0 hero-section' style={{minHeight: "100vh"}}>
            <div className='col-12 position-absolute z-1 hero-section-background-image' style={{height: "100vh"}}></div>

            <div className='col-11 col-lg-6 position-relative z-2 m-auto hero-section-text'>
              <h1 className='text-title-5 text-white text-center font-libre-caslon mb-0 hero-section-title'>OAZA HOTEL</h1>
              <p className='text-white text-center font-cormorant-regular fs-4'>{t('home_page_hero_section_catch_phrase')}</p>
            </div>

            <div className='col-11 position-relative z-2 d-flex justify-content-center justify-content-md-end mb-5'>
              <a href='https://www.booking.com/hotel/ua/oaza-iasinia.cs.html#room_110467301' target="_blank" className='bg-color-light-skin text-white fs-2 fw-semibold px-5 py-3 buton-hover-skin'>Book Now</a>
            </div>
        </section>

        <section className='container-fluid position-relative z-0 m-0 p-0 my-5'>
          <div className='row d-flex justify-content-center mx-0 my-5'>
            <div className='col-11 col-lg-9 col-xl-7'>
              <p className='text-center text-responsive-1 font-mont-light'>{t('about_dragobrat_text')}</p>
            </div>
          </div>
        </section>

        <section className='container-fluid position-relative z-0 m-0 p-0 mb-5 mt-2 d-flex justify-content-center align-items-center' style={{minHeight: "60vh"}}>
          <div className='row mx-0 my-5 d-flex justify-content-center'>
            <div className='col-11 col-xl-10 row-gap-3 column-gap-5 px-0 d-flex flex-column flex-xl-row justify-content-center justify-content-xl-start align-items-center align-items-xl-stretch'>
              <div className='col-md-10 col-lg-9 col-xl-6 col-xxl-5'>
                <img src={AbooutHotel} className='img-fluid' />
              </div>

              <div className='col-md-10 col-lg-9 col-xl-6 col-xxl-6 d-flex flex-column justify-content-between'>
                <p className='text-spacing-3 text-responsive-1 font-mont-light'>{t('about_hotel_text')}</p>

                <div className='d-flex align-items-start'>
                  <a href='/about-hotel/' className='bg-color-light-skin text-white text-spacing-3 font-alegreya-sans-bold fs-4 fw-semibold px-4 py-3 buton-hover-skin'>{t('about_hotel_title')}</a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className='container-fluid d-flex flex-column justify-content-between align-items-center advantages-section'>
          <div className='col-11 d-flex justify-content-center justify-conten-xl-between align-items-center'>
            <div className='col-2 col-sm-3 col-md-3 col-xl-4 col-xxl-4 m-0 p-0'><div className='w-0 border-bottom border-3 border-dark m-0 p-0 float-end overflow-hidden advantages-section-text-lines'></div></div>

            <div className='col-8 col-sm-6 col-md-5 col-xl-4 col-xxl-4 m-0 p-0'>
              <h1 className='text-title-2 text-dark text-center font-mont-semibold'>{t('home_page_advantages_section_title')}</h1>
            </div>

            <div className='col-2 col-sm-3 col-md-3 col-xl-4 col-xxl-4 m-0 p-0'><div className='w-0 border-bottom border-3 border-dark m-0 p-0 overflow-hidden advantages-section-text-lines'></div></div>
          </div>

          <div className='col-11 d-flex flex-wrap justify-content-between align-items-center my-5'>
            <div className='col-12 col-sm-6 col-md-5 col-xl-4 col-xxl-3 my-3 d-flex flex-row justify-content-center align-items-center'>
              <WifiIcon />
              <h4 className='font-mont-light mx-4 mb-0'>{t('home_page_advantages_section_wifi')}</h4>
            </div>

            <div className='col-12 col-sm-6 col-md-5 col-xl-4 col-xxl-3 my-3 d-flex flex-row justify-content-center align-items-center'>
              <DishesIcon />
              <h4 className='font-mont-light mx-4 mb-0'>{t('home_page_advantages_section_nutrition')}</h4>
            </div>

            <div className='col-12 col-sm-6 col-md-5 col-xl-4 col-xxl-3 my-3 d-flex flex-row justify-content-center align-items-center'>
              <LiftTwoIcon />
              <h4 className='font-mont-light mx-4 mb-0'>{t('home_page_advantages_section_pullers')}</h4>
            </div>

            <div className='col-12 col-sm-6 col-md-5 col-xl-4 col-xxl-3 my-3 d-flex flex-row justify-content-center align-items-center'>
              <ParkingIcon />
              <h4 className='font-mont-light mx-4 mb-0'>{t('home_page_advantages_section_cleaning')}</h4>
            </div>
          </div>
        </section>

        <section className='container-fluid position-relative z-0 p-0 mt-5 d-flex flex-column'>
          <div className='bg-color-dark py-4'>
            <h1 className='text-title-2 text-white text-center font-mont-semibold'>{t('home_page_favorite_rooms_title')}</h1>
          </div>
          {favoriteRooms.map((favoriteRoom, key) => (
            <FavoriteRooms key={key} favoriteRoom={favoriteRoom} index={key} />
          ))}
        </section>

        <section className='container-fluid bg-color-dark m-0 p-0 py-5' id='carousel-section' style={{minHeight: "100vh"}}>
          <div className='col-8'>
            <h1 className='text-title-2 text-white text-center font-libre-caslon carousel-title-text'>ARRIVAL IS JUST THE BEGINNING</h1>
          </div>

          <Carousel />

          <div className='col-12 mt-5 d-flex justify-content-center align-items-center'>
            <div className='col-4 border border-1 border-color-skin'></div>
          </div>
        </section>

        <Footer />
    </div>
  )
}

export default HomePage