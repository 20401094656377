import React, {useEffect, useState} from 'react';
import { format, roundToNearestHours } from 'date-fns';
import Cookies from 'js-cookie';
import axios from 'axios';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

import ImageSlider from '../ImageSlider';

const BookingPaymentInformation = ({chosenRoom, firstName, lastName, checkIn, checkOut, phone, amount, email, bookingData}) => {
    const [paymentResponse, setPaymentResponse] = useState([]);

    useEffect(() => {
        console.log(bookingData);
        const csrftoken = Cookies.get('csrftoken');
        axios.defaults.headers.post['X-CSRFToken'] = csrftoken;
        axios.post("/api/booking/payment/", {amount: amount, booking_id: bookingData.id}, {headers: {'Content-Type': 'application/json'}, withCredentials: false}).then((response) => {
            setPaymentResponse(response.data);
        });
    }, [])

  return (
    <div className='col-12 d-flex flex-column justify-content-center align-items-center'>
        <div className='col-11 d-flex flex-wrap justify-content-around'>
            <div className='col-5'>
                <ImageSlider images={chosenRoom?.images} />
            </div>
            <div className='col-5'>
                <div className='d-flex flex-column align-items-start'>
                    <h1 className='font-montserrat-bold'>{chosenRoom?.translations?.en?.name}</h1>
                    <p className='font-montserrat-regular'>{chosenRoom?.translations?.en?.description}</p>
                </div>
                
                <div className='d-flex flex-column align-items-start'>
                    <p className='font-montserrat-regular fs-5'><span className='font-montserrat-bold'>Full name:</span> {firstName} {lastName}</p>
                    <p className='font-montserrat-regular fs-5'><span className='font-montserrat-bold'>Email:</span> {email}</p>
                    <p className='font-montserrat-regular fs-5'><span className='font-montserrat-bold'>Phone number:</span> {phone}</p>
                </div>
            </div>
        </div>
        <form method="POST" action="https://www.liqpay.ua/api/3/checkout" accept-charset="utf-8">
            <input type="hidden" name="data" value={paymentResponse?.data}/>
            <input type="hidden" name="signature" value={paymentResponse?.signature}/>
            <input type="image" src="//static.liqpay.ua/buttons/payUk.png"/>
        </form>
        <div id="liqpay_checkout"></div>
    </div>
  )
}

export default BookingPaymentInformation